export default function IconSolidVideo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10" id="Play-List-1--Streamline-Micro">
      <desc>{'Play List 1 Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        fill="currentColor"
        d="M10 1.625A1.627 1.627 0 0 0 8.375 0l-6.75 0A1.627 1.627 0 0 0 0 1.625L0 8.5A1.5 1.5 0 0 0 1.5 10h7A1.5 1.5 0 0 0 10 8.5V1.625Zm-5.577 -0.21a0.249 0.249 0 0 1 0.235 -0.165H5.8a0.25 0.25 0 0 1 0.235 0.335l-0.546 1.5a0.25 0.25 0 0 1 -0.235 0.165H4.112a0.25 0.25 0 0 1 -0.235 -0.335l0.546 -1.5Zm-1.937 1.67a0.25 0.25 0 0 1 -0.235 0.165H1.5A0.25 0.25 0 0 1 1.25 3V1.625a0.375 0.375 0 0 1 0.375 -0.375H2.8a0.25 0.25 0 0 1 0.235 0.335l-0.549 1.5ZM6.68 6.849l-2.456 1.47a0.39 0.39 0 0 1 -0.395 0 0.4 0.4 0 0 1 -0.2 -0.354V5.03a0.4 0.4 0 0 1 0.6 -0.354L6.68 6.151a0.41 0.41 0 0 1 0 0.7v-0.002ZM8.75 3a0.25 0.25 0 0 1 -0.25 0.25H7.112a0.25 0.25 0 0 1 -0.235 -0.335l0.546 -1.5a0.249 0.249 0 0 1 0.235 -0.165h0.717a0.375 0.375 0 0 1 0.375 0.375V3Z"
        strokeWidth={1}
      />
    </svg>
  );
}

import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

const BaseRates = {
  COMBO: '525',
  FIVE: '160',
  FPV_BOTH: '630',
  FPV_OUTSIDE: '315',
  POI: '125',
  POI_EACH: '20',
  RUSH: '150',
  TEN: '315',
  TWILIGHT: '315',
  VIDEO: '315',
};

export const BreAerialRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    COMBO: '840',
    FIVE: '210',
    POI: '160',
    TEN: '420',
    VIDEO: '420',
  },
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
};

import { EnvelopeCount, EnvelopePaper } from '~/tenants/bre/performable/print/envelopes/BreEnvelopesConfig';

type CountMap = Record<EnvelopeCount, string>;

type PaperMap = Record<EnvelopePaper, Partial<CountMap>>;

export const BreEnvelopesRate: PaperMap = {
  smooth_white: {
    250: '195',
    500: '295',
    1000: '395',
    2500: '575',
  },
  premium_linen_white: {
    250: '345',
    500: '415',
    1000: '495',
    2500: '650',
  },
};
export const BreEnvelopesPay: PaperMap = {
  smooth_white: {
    250: '165',
    500: '255',
    1000: '340',
    2500: '500',
  },
  premium_linen_white: {
    250: '300',
    500: '360',
    1000: '430',
    2500: '565',
  },
};

import { useZodFormFieldSingle, useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { useRef } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export default function BreOrderForm() {
  const type = useZodFormFieldSingleValue<BreOrderType>('type');
  const preselected = useRef(type !== undefined);

  return (
    <FormGroup>
      {preselected.current && <ZodFieldHidden name="type" />}
      {!preselected.current && (
        <FormHorizontal name="type" label="Type">
          <ZodFieldSelect
            name="type"
            options={[
              { label: 'Residential', value: BreOrderType.RESIDENTIAL },
              { label: 'Commercial', value: BreOrderType.COMMERCIAL },
              { label: 'Rental', value: BreOrderType.RENTAL },
              { label: 'Agent Marketing', value: BreOrderType.MARKETING },
            ]}
          />
        </FormHorizontal>
      )}
      {type !== BreOrderType.MARKETING && (
        <FormHorizontal name="sqft" label="Square Feet">
          <ZodFieldInput name="sqft" type="number" />
        </FormHorizontal>
      )}
      {type === BreOrderType.RESIDENTIAL && (
        <>
          <FormHorizontal name="beds" label="Beds">
            <ZodFieldInput name="beds" type="number" />
          </FormHorizontal>
          <FormHorizontal name="baths" label="Baths">
            <ZodFieldInput name="baths" type="number" />
          </FormHorizontal>
        </>
      )}
    </FormGroup>
  );
}

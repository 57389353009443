import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export enum TwtEditingType {
  BLUE_SKY = 'BLUE_SKY',
  GRASS_COLOR = 'GRASS_COLOR',
  GRASS_REPLACE = 'GRASS_REPLACE',
  GRASS_AERIAL = 'GRASS_AERIAL',
  DRIVEWAY = 'DRIVEWAY',
  CROP = 'CROP',
  COLOR = 'COLOR',
  BOUNDARY = 'BOUNDARY',
  OBJECT_REMOVAL = 'REMOVAL',
  FIREPLACE = 'FIREPLACE',
  TV_SCREEN = 'TV_SCREEN',
  SUNSET_SKY = 'SUNSET_SKY',
}

export const TWT_EDITING_LABELS: Record<TwtEditingType, string> = {
  [TwtEditingType.BLUE_SKY]: 'Blue Sky',
  [TwtEditingType.GRASS_COLOR]: 'Green Grass - Color Existing Grass',
  [TwtEditingType.GRASS_REPLACE]: 'Green Grass Replacement - Add Missing/Replace',
  [TwtEditingType.GRASS_AERIAL]: 'Green Grass for Aerials',
  [TwtEditingType.DRIVEWAY]: 'Driveway Cleanup',
  [TwtEditingType.CROP]: 'Crop Image',
  [TwtEditingType.COLOR]: 'Color Reprocess',
  [TwtEditingType.BOUNDARY]: 'Boundary Line Adjustment',
  [TwtEditingType.OBJECT_REMOVAL]: 'Object Removal',
  [TwtEditingType.FIREPLACE]: 'Fire in fireplace or adding other objects',
  [TwtEditingType.TV_SCREEN]: 'Adding photo to TV screen',
  [TwtEditingType.SUNSET_SKY]: 'Sunset sky replacement',
};

export default createPerformableConfig(
  'editing',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      images: z
        .array(
          z.object({
            type: z.nativeEnum(TwtEditingType),
            deliverable_id: z.string(),
            notes: z.string().optional(),
          }),
        )
        .min(1, 'Please select at least one image.'),
    }),
  },
  {
    name: 'Photo Editing',
    short: '',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/video/1.jpg',
    images: [],
  },
);

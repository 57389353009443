import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';

export default function BreOrderWizardType() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard
        value={BreOrderType.RESIDENTIAL}
        image="" // TODO: Add highlight image
        title="Residential"
      >
        Residential listings including single family homes, condominiums, townhomes, etc.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.COMMERCIAL}
        image="" // TODO: Add highlight image
        title="Commercial"
      >
        Commercial listing.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.RENTAL}
        image="" // TODO: Add highlight image
        title="Rental"
      >
        Lease listings including single family homes, condominiums, townhomes, etc.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.MARKETING}
        image="" // TODO: Add highlight image
        title="Agent Marketing"
      >
        Professional marketing materials.
      </OrderWizardTypeCard>
    </div>
  );
}

import DatePicker from './DatePicker';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTz } from '../../hooks/useTz';

export type InputDateProps = {
  value?: TDateISODate;
  name?: string;
  filter?: (date: DateTime) => boolean;
  disabled?: boolean;
} & (
  | {
      required: true;
      onChange?: (date: TDateISODate) => void;
    }
  | {
      required: false | undefined;
      onChange?: (date: TDateISODate | null) => void;
    }
);

export default function InputDate(props: InputDateProps) {
  const tz = useTz();

  const [show, setShow] = useState(!!props.required);

  const calculateDefault = useMemo(() => {
    let date = tz();

    if (props.filter) {
      let attempts = 0;

      while (!props.filter(date)) {
        date = date.plus({ day: 1 });

        if (attempts++ >= 45) {
          throw new Error('Filter did not resolve within 45 days.');
        }
      }
    }

    return date;
  }, [props.filter]);

  const defaultValue = useMemo(() => props.value ?? calculateDefault.toSQLDate(), []);

  const onChange = () => {
    if (props.disabled || !props.onChange) {
      return () => {};
    }

    return (date: TDateISODate | null) => {
      if (props.required && date) {
        props.onChange?.(date);
      } else if (!props.required) {
        props.onChange?.(date);
      }
    };
  };

  useEffect(() => {
    if (props.filter && props.value && !props.filter(tz(props.value))) {
      props.onChange?.('' as TDateISODate);
    }
  }, [props.filter, props.value, props.onChange]);

  return (
    <>
      {show && <DatePicker value={props.value ?? defaultValue} filter={props.filter} onChange={onChange()} />}
      {props.value && props.name && <input type="hidden" name={props.name} value={props.value} />}
    </>
  );
}

import { PostcardPrintOnlyCount } from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyConfig';

type CountMap = Record<PostcardPrintOnlyCount, string>;

export const BrePostcardsPrintOnlyRate: CountMap = {
  50: '130',
  100: '140',
  200: '160',
  250: '175',
  300: '185',
  400: '210',
  500: '230',
};

export const BrePostcardsPrintOnlyPay: CountMap = {
  50: '110',
  100: '120',
  200: '140',
  250: '150',
  300: '160',
  400: '180',
  500: '200',
};

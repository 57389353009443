import Message from '~/components/Message';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { capitalize } from '~/lib/format';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { CONTENT_WRITING_RATE, toNeedBy } from '~/tenants/bre/performable/common';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import { FlyerCount, FlyerPaper, FlyerSide, countSchema, paperSchema, sideSchema } from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';
import { BreFlyersRate } from '~/tenants/bre/performable/print/flyers/BreFlyersData';
import BreFlyersJob from '~/tenants/bre/performable/print/flyers/BreFlyersJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRadioCards } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const SIDE_OPTS = sideSchema.options.map<InputRadioCardOption>((value) => ({
  title: BreFlyersJob.sidesText(value),
  description: 'flyers',
  value,
}));

const COUNT_OPTS = countSchema.options.map<InputRadioCardOption>((value) => ({
  title: `${value} pieces`,
  description: 'copies',
  value,
}));

export default function BreFlyersForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const sides = useZodFormFieldSingleValue<FlyerSide>('sides') ?? '1';
  const count = useZodFormFieldSingleValue<FlyerCount>('count') ?? '50';
  const paper = useZodFormFieldSingleValue<FlyerPaper>('paper') ?? 'low_gloss';
  const needBy = useZodFormFieldSingleValue('need_by', toNeedBy);

  const paperOptions = paperSchema.options
    .filter((value) => BreFlyersRate[needBy][sides]?.[value])
    .map<InputSelectOption>((value) => ({
      label: BreFlyersJob.paperType({ paper: value, sides, }),
      value,
    }));

  const designOptions = layoutSchema.options.map<InputRadioCardOption>((value) => {
    const rate = BreDesignRate[value][`flyer_${sides}`];

    return {
      title: `${capitalize(value)} Layout`,
      cost: rate,
      disabled: !rate,
      value,
    };
  });

  return (
    <FormGroup>
      <FormHorizontal name="sides" label="Sides" required>
        <ZodFieldRadioCards name="sides" options={SIDE_OPTS} />
      </FormHorizontal>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldRadioCards name="count" options={COUNT_OPTS} cols={2} />
      </FormHorizontal>
      <FormHorizontal name="paper" label="Paper" required>
        <ZodFieldSelect name="paper" options={paperOptions} />
      </FormHorizontal>
      {sides === '4' && (
        <FormHorizontal name="no_free_uv" label="UV Cover">
          <label className="flex gap-2 cursor-pointer" htmlFor="no_free_uv">
            <ZodFieldCheckbox id="no_free_uv" name="no_free_uv" />
            Opt-out of free UV cover
          </label>
        </FormHorizontal>
      )}
      <FormHorizontal
        name="need_by"
        label="Need By"
        description="Same-day printing is only available for orders placed before 12 PM (noon)."
        required
      >
        <ZodFieldDate
          name="need_by"
          filter={(date) => {
            const today = tz().startOf('day');
            const isMonToThu = tz().weekday < 5;
            const isBeforeNoon = tz() <= today.set({ hour: 12 });
            const hasSameDayFee = !!BreFlyersRate.same_day[sides]?.[paper]?.[count];
            const nextAvailable =  !isMonToThu || !isBeforeNoon || !hasSameDayFee
              ? plusBusinessDays(today, 1)
              : today;

            return isBusinessDay(date) && date >= nextAvailable;
          }}
        />
      </FormHorizontal>
      {needBy === 'same_day' && (
        <>
          <Message type="info">
            <b>Remember:</b> To ensure same-day printing, please approve the
            designs by 10 AM. If approval is received after this time, same-day
            printing won't be possible, and the extra fee won't apply.
          </Message>
          <Message type="warning">
            <b>NOTICE:</b> Same-day fee may differ and could impact other charges
            if adjustments are needed to ensure timely completion of your order.
          </Message>
        </>
      )}
      <BreDelivery
        name="delivery"
        label="Delivery"
        context={context}
        required
      />

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="content_writing"
          title="Content writing"
          description="Add content writing for all of your marketing pieces"
          image="" // ? what image should we use here
          persona={persona}
          cost={CONTENT_WRITING_RATE}
        />
        <TenantPackageAddOnRadioCards
          name="design"
          title="Design"
          description="Include layout design to your flyers"
          image="" // ? what image should we use here
          persona={persona}
          options={designOptions}
          cols={2}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

import { DeliverableData, DeliverableFile, DeliverableType } from '~common/model/Deliverable';
import DeliverableRenderMatterport from '~/components/deliverable/DeliverableRenderMatterport';
import DeliverableRenderPhoto from '~/components/deliverable/DeliverableRenderPhoto';
import DeliverableRenderVideo from '~/components/deliverable/DeliverableRenderVideo';
import DeliverableRenderFloorplan from '~/components/deliverable/DeliverableRenderFloorplan';
import DeliverableRenderAerial from '~/components/deliverable/DeliverableRenderAerial';
import DeliverableRenderSocial from '~/components/deliverable/DeliverableRenderSocial';
import DeliverableRenderBrochure from '~/components/deliverable/DeliverableRenderBrochure';
import DeliverableRenderSlideshow from '~/components/deliverable/DeliverableRenderSlideshow';

export default function DeliverableRender({ data, file }: { data: DeliverableData; file?: DeliverableFile }) {
  if (data.type === DeliverableType.MATTERPORT) {
    return <DeliverableRenderMatterport data={data} />;
  }

  if (data.type === DeliverableType.FLOORPLAN && file?.image) {
    return <DeliverableRenderFloorplan data={data} image={file.image} />;
  }

  if (data.type === DeliverableType.PHOTO && file?.image) {
    return <DeliverableRenderPhoto data={data} image={file.image} />;
  }

  if (data.type === DeliverableType.AERIAL && file?.image) {
    return <DeliverableRenderAerial data={data} image={file.image} video={file.video} />;
  }

  if (data.type === DeliverableType.SOCIAL && file?.image) {
    return <DeliverableRenderSocial data={data} image={file.image} video={file.video} />;
  }

  if (data.type === DeliverableType.SLIDESHOW && file?.video) {
    return <DeliverableRenderSlideshow video={file.video} />;
  }

  if (data.type === DeliverableType.VIDEO && file?.video) {
    return <DeliverableRenderVideo data={data} video={file.video} />;
  }

  if (data.type === DeliverableType.BROCHURE && file?.pdf) {
    return <DeliverableRenderBrochure url={file.pdf} />;
  }

  return <></>;
}

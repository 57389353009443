import { DeliverableFileVideo } from '~common/model/Deliverable';
import VideoPlayer from '~/components/VideoPlayer';

export default function DeliverableRenderSlideshow({ video }: { video: DeliverableFileVideo }) {
  return (
    <div className="w-full h-full relative">
      <div className="absolute inset-0 w-full h-full">
        <VideoPlayer m3u8={video.m3u8} mp4={video.mp4} />
      </div>
    </div>
  );
}

import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'dusk',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      quantity: z.coerce.number().positive(),
    }),
  },
  {
    name: 'Day to Dusk',
    group: 'Listing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: 'Can create a day image digitally into a dusk image.',
  },
);

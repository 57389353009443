import z from 'zod';
import dedent from 'dedent';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';
import { deliverySchema } from '~/tenants/bre/performable/common';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';

export type BrochureSide = z.infer<typeof sideSchema>;

export type BrochureCount = z.infer<typeof countSchema>;

export type BrochurePaper = z.infer<typeof paperSchema>;

export const sideSchema = z.enum([
  '8',
  '12',
  '16',
  '20',
  '24',
  '28',
]);

export const countSchema = z.enum([
  '50',
  '100',
  '150',
  '200',
]);

export const paperSchema = z.enum([
  'heavy_low_gloss',
  'heavy_low_gloss_uv',
  'heavy_heavy_gloss_uv',
  'super_heavy_low_gloss_uv',
  'super_heavy_heavy_gloss_uv',
]);

export default createPerformableConfig(
  'brochures',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      sides: sideSchema,
      count: countSchema,
      paper: paperSchema,
      need_by: zodTDateISODate(),
      delivery: deliverySchema,
      no_free_uv: coerceBoolean().optional(),
      content_writing: coerceBoolean().optional(),
      design: layoutSchema.optional(),
    }),
  },
  {
    name: 'Brochures',
    group: 'Property Marketing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: dedent`
      Print professional brochures from 8 to 24 pages on premium paper. Need \
      content? Add expert writing for a small fee!
    `,
  },
);

export default function IconEdit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 10"
      id="Pencil-Edit--Streamline-Micro"
      width="100%"
      height="100%"
    >
      <desc>{'Pencil Edit Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m3.71998 8.78 -3.187998 0.7 0.699998 -3.189 5.562 -5.584997c0.14731 -0.127521 0.33901 -0.19171 0.53341 -0.178601 0.19439 0.01311 0.37574 0.102457 0.50459 0.248601L9.21698 2.16c0.1466 0.12905 0.23614 0.31089 0.24907 0.50577 0.01293 0.19487 -0.05181 0.38695 -0.18007 0.53423l-5.566 5.58Z"
        strokeWidth={1}
      />
    </svg>
  );
}

import { DeliverableDataAerial, DeliverableFileVideo } from '~common/model/Deliverable';
import { FileImageSizes } from '~/.server/drizzle/entity/FileEntity';
import Sections from '~/components/layout/Sections';
import VideoPlayer from '~/components/VideoPlayer';

export default function DeliverableRenderAerial({
  data,
  image,
  video,
}: {
  data: DeliverableDataAerial;
  image: FileImageSizes;
  video?: DeliverableFileVideo;
}) {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="text-sm text-center">{data.caption ?? 'No Caption Provided'}</div>
      <div className="flex-1 relative">
        {video ? (
          <div className="absolute inset-0 w-full h-full">
            <VideoPlayer m3u8={video.m3u8} mp4={video.mp4} />
          </div>
        ) : (
          <img
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            srcSet={`${image.large_1x}, ${image.large_2x} 2x`}
            className="absolute inset-0 w-full h-full object-contain max-h-[800px] select-none"
          />
        )}
      </div>
    </div>
  );
}

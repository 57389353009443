import z from 'zod';
import * as v from 'valibot';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, coerceOptionalPositiveNumber, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'matterport',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      outdoor_back: coerceBoolean().optional(),
      outdoor_front: coerceBoolean().optional(),
      outdoor_commercial: coerceBoolean().optional(),
      plan: z.enum(['basic', 'advanced']).optional(),
      sqft: coerceOptionalPositiveNumber(),
    }),
  },
  {
    name: '3D Matterport',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
    images: [],
    submit: v.object({
      sqft: v.pipe(v.number(), v.integer(), v.minValue(1)),
    }),
  },
);

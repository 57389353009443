import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { countSchema } from '~/tenants/bre/performable/print/covers/BreCoversConfig';
import { BreCoversRate } from '~/tenants/bre/performable/print/covers/BreCoversData';
import { TenantPerformableFormProps } from '~/tenants/common/form';

const COUNT_OPTS = countSchema.options.map<InputRadioCardOption>((value) => {
  const rate = BreCoversRate[value];

  return {
    title: `${value} covers`,
    description: `2 sets of ${Number(value) / 2}`,
    cost: rate,
    disabled: !rate,
    value,
  };
});

export default function BreCoversForm({ context }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();

  return (
    <FormGroup>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldRadioCards name="count" options={COUNT_OPTS} cols={2} />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 7)}
        />
      </FormHorizontal>
      <BreDelivery
        name="delivery"
        label="Delivery"
        context={context}
        required
        free
      />
    </FormGroup>
  );
}

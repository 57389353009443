import { DeliverableType } from '~common/model/Deliverable';
import IconMatterport from '~/components/icons/brand/IconMatterport';
import IconSolidVideo from '~/components/icons/streamline/solid/IconSolidVideo';
import IconSolidFloorplan from '~/components/icons/streamline/solid/IconSolidFloorplan';
import IconSolidPhoto from '~/components/icons/streamline/solid/IconSolidPhoto';
import IconSolidAerial from '~/components/icons/streamline/solid/IconSolidAerial';

export default function DeliverableIcon({ type }: { type: DeliverableType }) {
  if (type === DeliverableType.VIDEO || type === DeliverableType.SOCIAL || type === DeliverableType.SLIDESHOW) {
    return <IconSolidVideo />;
  }

  if (type === DeliverableType.MATTERPORT) {
    return <IconMatterport />;
  }

  if (type === DeliverableType.FLOORPLAN) {
    return <IconSolidFloorplan />;
  }

  if (type === DeliverableType.PHOTO) {
    return <IconSolidPhoto />;
  }

  if (type === DeliverableType.AERIAL) {
    return <IconSolidAerial />;
  }

  return <></>;
}

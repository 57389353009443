import { TenantProvider } from '../../common/TenantPersona';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';

export enum BreProviderId {
  JOSEPH_IRASUSTA = 1,
  KWASI_ACHIAW = 2,
  NOEL_DASMARINAS = 3,
  ALICIA_GARCIA = 4,
  DAVID_DINDACK = 5,
  IVAN_MARTINEZ = 6,
  EMILY_MCLAUGHRY = 7,
  LAUREN_DINDAK = 8,
  CHRIS_RICKETTS = 9,
  ZACH_CRAWFORD = 10,
  GLENN_ROSE = 11,
  BILL_ROBINSON = 12,
}

export interface BreProviderStatic {
  performables: {
    aerial?: boolean;
    photos?: boolean;
    matterport?: boolean;
    cinematic?: boolean;
    floorplan?: boolean;
    social?: boolean;
    print?: boolean;
    portraits?: boolean;
  };
}

export default class BreProvider extends TenantProvider<BreProviderStatic, BreOrderContext> {
  canPerform(_context: BreOrderContext, job: BreOrderContext['jobs'][0]): true | string {
    if (job.performable_id === BreAerialConfig.id) {
      if (this.config.performables.aerial) {
        return true;
      }

      return 'This provider does not do Aerial jobs.';
    }

    if (job.performable_id === BrePhotosConfig.id) {
      if (this.config.performables.photos) {
        return true;
      }

      return 'This provider does not do Photo jobs.';
    }

    if (job.performable_id === BreFloorplanConfig.id) {
      if (this.config.performables.floorplan) {
        return true;
      }

      return 'This provider does not do Floorplan jobs.';
    }

    if (job.performable_id === BreSocialConfig.id) {
      if (this.config.performables.social) {
        return true;
      }

      return 'This provider does not do Social jobs.';
    }

    if (job.performable_id === BreCinematicConfig.id) {
      if (this.config.performables.cinematic) {
        return true;
      }

      return 'This provider does not do Cinematic jobs.';
    }

    if (job.performable_id === BrePortraitsConfig.id) {
      if (this.config.performables.portraits) {
        return true;
      }

      return 'This provider does not do Portraits jobs.';
    }

    throw new Error('Unrecognized performable id.');
  }

  get performableIds(): string[] {
    const ids = [];

    if (this.config.performables.aerial) {
      ids.push(BreAerialConfig.id);
    }

    if (this.config.performables.photos) {
    }

    return ids;
  }
}

import BreJob from '~/tenants/bre/model/BreJob';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { DeliverableType } from '~common/model/Deliverable';
import { match } from 'ts-pattern';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialData';

export default class BreAerialJob extends BreJob<typeof BreAerialConfig> {
  get performable() {
    return BreAerialConfig;
  }

  get configurable() {
    return true;
  }

  get submission() {
    return this.metadata.type === 'video'
      || this.metadata.type === 'combo'
      || this.metadata.fpv !== undefined;
  }

  get deliverable() {
    return DeliverableType.AERIAL;
  }

  get editing() {
    return this.submission
      ? !this.metadata.self_edited
      : super.editing;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreAerialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'five',
    };
  }

  // TODO: Missiing POIs times?
  onsite(): number {
    const photos = match(this.metadata.type)
      .with('five', () => 25)
      .with('ten', () => 35)
      .with('video', () => 30)
      .with('combo', () => 40)
      .exhaustive();
    const fpv = match(this.metadata.fpv)
      .with('outside', () => 30)
      .with('both', () => 60)
      .otherwise(() => 0);
    const twilight = this.metadata.twilight ? 25 : 0;

    return photos + fpv + twilight;
  }

  revenueLines(): TenantJobLine[] {
    const {
      COMBO,
      FIVE,
      FPV_BOTH,
      FPV_OUTSIDE,
      POI,
      POI_EACH,
      RUSH,
      TEN,
      TWILIGHT,
      VIDEO,
    } = BreAerialRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];
    const rate = match(this.metadata.type)
      .with('five', () => FIVE)
      .with('ten', () => TEN)
      .with('video', () => VIDEO)
      .with('combo', () => COMBO)
      .exhaustive();

    lines.push({
      id: 'aerial_photo_video',
      description: match(this.metadata.type)
        .with('five', 'ten', () => 'Aerial Photos')
        .with('video', () => 'Aerial Video')
        .with('combo', () => 'Aerial Photo & Video')
        .exhaustive(),
      amount: new Big(rate),
    });

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Photos',
        amount: new Big(TWILIGHT),
      });
    }

    if (this.metadata.fpv) {
      const location = match(this.metadata.fpv)
        .with('outside', () => 'Outside only')
        .with('both', () => 'Inside & Outside')
        .exhaustive();
      const rate = match(this.metadata.fpv)
        .with('outside', () => FPV_OUTSIDE)
        .with('both', () => FPV_BOTH)
        .exhaustive();

      lines.push({
        id: 'fpv',
        description: `First-Person View (${location})`,
        amount: new Big(rate),
      });
    }

    if (this.metadata.poi && this.metadata.poi.length > 0) {
      lines.push({
        id: 'poi',
        description: 'Points of Interest',
        amount: this.metadata.poi.length > 7
          ? new Big(POI_EACH).times(this.metadata.poi.length - 7).plus(POI)
          : new Big(POI),
      });
    }

    if (this.metadata.rush && this.metadata.type === 'combo') {
      lines.push({
        id: 'rush_fee',
        description: 'Next-day video rush fee',
        amount: new Big(RUSH),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { TWILIGHT } = BreAerialRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];
    const rate = match(this.metadata.type)
      .with('five', () => '150')
      .with('ten', () => '299')
      .with('video', () => '299')
      .with('combo', () => '500')
      .exhaustive();
    const split = match(this.metadata.type)
      .with('five', 'ten', () => '0.65')
      .otherwise(() => this.metadata.self_edited ? '0.5' : '0.7');

    lines.push({
      id: 'aerial_photo_video',
      description: match(this.metadata.type)
        .with('five', 'ten', () => 'Aerial Photos')
        .with('video', () => 'Aerial Video')
        .with('combo', () => 'Aerial Photo & Video')
        .exhaustive(),
      amount: new Big(rate).times(split),
    });

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Photos',
        amount: new Big(TWILIGHT).div('1.05').times('0.65'),
      });
    }

    if (this.metadata.fpv) {
      const location = match(this.metadata.fpv)
        .with('outside', () => 'Outside only')
        .with('both', () => 'Inside & Outside')
        .exhaustive();
      const rate = match(this.metadata.fpv)
        .with('outside', () => '299')
        .with('both', () => '600')
        .exhaustive();

      lines.push({
        id: 'fpv',
        description: `First-Person View (${location})`,
        amount: new Big(rate).times('0.7'),
      });
    }

    return lines;
  }
}

import z from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export default createPerformableConfig(
  'slideshow',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: 'Slideshow Video',
    group: 'Listing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
  },
);

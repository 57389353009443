import {
  PostcardMailingPaper,
  PostcardMailingPostage,
  PostcardMailingSize,
} from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingConfig';

interface CountMap {
  upTo: number;
  value: string;
}

type RateMap = Record<PostcardMailingPaper, CountMap[]>;

type PostageMap = Partial<Record<PostcardMailingPostage, string>>;

export const BrePostcardsMailingRate: Record<PostcardMailingSize, RateMap> = {
  half: {
    heavy_gloss: [
      { upTo: 250, value: '190' },
      { upTo: 500, value: '305' },
      { upTo: 1000, value: '410' },
      { upTo: 2500, value: '480' },
      { upTo: 5000, value: '805' },
      { upTo: 7500, value: '1035' },
    ],
    super_heavy_gloss_uv: [
      { upTo: 250, value: '225' },
      { upTo: 500, value: '330' },
      { upTo: 1000, value: '425' },
    ],
  },
  jumbo: {
    heavy_gloss: [
      { upTo: 250, value: '320' },
      { upTo: 500, value: '440' },
      { upTo: 1000, value: '535' },
      { upTo: 2500, value: '776' },
      { upTo: 5000, value: '1035' },
    ],
    super_heavy_gloss_uv: [
      { upTo: 250, value: '345' },
      { upTo: 500, value: '475' },
    ],
  },
  flats: {
    heavy_gloss: [
      { upTo: 250, value: '330' },
      { upTo: 500, value: '445' },
      { upTo: 1000, value: '560' },
    ],
    super_heavy_gloss_uv: [],
  },
};

export const BrePostcardsMailingPay: Record<PostcardMailingSize, RateMap> = {
  half: {
    heavy_gloss: [
      { upTo: 250, value: '165' },
      { upTo: 500, value: '265' },
      { upTo: 1000, value: '355' },
      { upTo: 2500, value: '400' },
      { upTo: 5000, value: '700' },
      { upTo: 7500, value: '900' },
    ],
    super_heavy_gloss_uv: [
      { upTo: 250, value: '195' },
      { upTo: 500, value: '285' },
      { upTo: 1000, value: '370' },
    ],
  },
  jumbo: {
    heavy_gloss: [
      { upTo: 250, value: '275' },
      { upTo: 500, value: '380' },
      { upTo: 1000, value: '465' },
      { upTo: 2500, value: '675' },
      { upTo: 5000, value: '900' },
    ],
    super_heavy_gloss_uv: [
      { upTo: 250, value: '300' },
      { upTo: 500, value: '410' },
    ],
  },
  flats: {
    heavy_gloss: [
      { upTo: 250, value: '285' },
      { upTo: 500, value: '385' },
      { upTo: 1000, value: '485' },
    ],
    super_heavy_gloss_uv: [],
  },
};

export const BrePostcardsPostageRate: Record<PostcardMailingSize, PostageMap> = {
  half: {
    standard: '0.42',
    first_class: '0.73',
  },
  jumbo: {
    standard: '0.42',
    first_class: '0.73',
  },
  flats: {
    standard: '0.996111',
  },
};

export const BrePostcardsPostagePay: Record<PostcardMailingSize, PostageMap> = {
  half: {
    standard: '0.405',
    first_class: '0.695',
  },
  jumbo: {
    standard: '0.405',
    first_class: '0.695',
  },
  flats: {
    standard: '0.94424731',
  },
};

export const BrePostcardsHandleRate: Record<PostcardMailingSize, CountMap[]> = {
  half: [
    { upTo: 1000, value: '86' },
    { upTo: 2000, value: '215' },
    { upTo: 3000, value: '245' },
    { upTo: 4000, value: '270' },
    { upTo: 5000, value: '300' },
    { upTo: 7500, value: '375' },
  ],
  jumbo: [
    { upTo: 1000, value: '86' },
    { upTo: 2000, value: '215' },
    { upTo: 3000, value: '245' },
    { upTo: 4000, value: '270' },
    { upTo: 5000, value: '300' },
    { upTo: 7500, value: '375' },
  ],
  flats: [
    { upTo: 1000, value: '86' },
    { upTo: 2000, value: '215' },
    { upTo: 3000, value: '245' },
    { upTo: 4000, value: '270' },
    { upTo: 5000, value: '300' },
    { upTo: 7500, value: '375' },
  ],
};

export const BrePostcardsHandlePay: Record<PostcardMailingSize, CountMap[]> = {
  half: [
    { upTo: 1000, value: '75' },
    { upTo: 2000, value: '185' },
    { upTo: 3000, value: '210' },
    { upTo: 4000, value: '235' },
    { upTo: 5000, value: '260' },
    { upTo: 7500, value: '325' },
  ],
  jumbo: [
    { upTo: 1000, value: '75' },
    { upTo: 2000, value: '185' },
    { upTo: 3000, value: '210' },
    { upTo: 4000, value: '235' },
    { upTo: 5000, value: '260' },
    { upTo: 7500, value: '325' },
  ],
  flats: [
    { upTo: 1000, value: '75' },
    { upTo: 2000, value: '185' },
    { upTo: 3000, value: '210' },
    { upTo: 4000, value: '235' },
    { upTo: 5000, value: '260' },
    { upTo: 7500, value: '325' },
  ],
};

export function isUpTo(value: number): (countMap: CountMap) => boolean {
  return ({ upTo }) => value <= upTo;
}

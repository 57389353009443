import { match } from 'ts-pattern';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { typeSchema } from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { BreWebsiteRate } from '~/tenants/bre/performable/website/BreWebsiteData';
import BreWebsiteDomianAddOn from '~/tenants/bre/performable/website/BreWebsiteDomianAddOn';
import BreWebsiteJob from '~/tenants/bre/performable/website/BreWebsiteJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreWebsiteForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { RENEWAL, WEBSITE } = BreWebsiteRate[context.metadata.type];

  const typeOptions = typeSchema.options.map<InputRadioCardOption>((value) => ({
    title: BreWebsiteJob.typeText(value),
    cost: match(value)
      .with('website', () => WEBSITE)
      .with('renewal', () => RENEWAL)
      .exhaustive(),
    value,
  }));

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type" required>
        <ZodFieldRadioCards
          name="type"
          options={typeOptions}
          cols={2}
        />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <BreWebsiteDomianAddOn type={context.metadata.type} persona={persona} />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

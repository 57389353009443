import { useEffect } from 'react';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { BreOrderMetadata } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosExtra from '~/tenants/bre/performable/photos/BrePhotosExtra';
import { PHOTOS_OPTS } from '~/tenants/bre/performable/photos/BrePhotosForm';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';

interface BrePhotosFieldsProps {
  metadata: BreOrderMetadata;
}

export default function BrePhotosFields({ metadata }: BrePhotosFieldsProps) {
  const [_name, _photos, setPhotos] = useZodFormFieldSingle('photos');

  useEffect(() => {
    const recommended = BrePhotosJob.recommendedPhotos(metadata);

    setPhotos(`${recommended}`);
  }, [metadata]);

  return (
    <>
      <FormHorizontal name="photos" label="Minimum number of Photos recommended for your Property size" required>
        <ZodFieldSelect name="photos" options={PHOTOS_OPTS} />
      </FormHorizontal>
      <FormHorizontal label="Unsure about the package?">
        <BrePhotosExtra metadata={metadata} />
      </FormHorizontal>
    </>
  );
}

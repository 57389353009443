import FormGroup from '~/components/form/FormGroup';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreBoostAddOn } from '~/tenants/bre/performable/BreBoostAddOn';
import BreSocialAddOns from '~/tenants/bre/performable/social/BreSocialAddOns';
import { BreSocialRate } from '~/tenants/bre/performable/social/BreSocialData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreSocialForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { RUSH } = BreSocialRate[context.metadata.type];

  return (
    <FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <BreSocialAddOns type={context.metadata.type} persona={persona} />
        <BreBoostAddOn name="boost" persona={persona} />
        <TenantPackageAddOnCheckbox
          name="rush"
          title="Rush"
          description="Need your video by the next business day instead of the second business day?"
          image="" // ? what should we use here
          persona={persona}
          cost={RUSH}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

/*

Quick overview of the main areas of the home. Rendered in a Vertical - Up to a 1-minute - Clip for quick upload to any social media platform and reel. Fun, Quick, and Effective.

A quick agent intro (10-15 seconds) can be added on-site.

Post Completion, narration can be overlaid with or without on-site agent intro.

You can choose to boost these clips on social media if you have a Social Media Boost selected under the Single Property Website Page.

----

Quick overview of the main areas of the home. Rendered in a Vertical - Up to a 1-minute - Clip for quick upload to any social media platform and reel.
 */

/*
Voice Narration read by Kwasi

BREM Provider Kwasi will dictate the script of the property narration.
 */

import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

export default function BreCustomForm() {
  return (
    <FormGroup>
      <FormHorizontal name="description" label="Description" required>
        <ZodFieldTextarea name="description" />
      </FormHorizontal>
      <FormHorizontal name="rate" label="Price">
        <ZodFieldInput name="rate" type="number" />
      </FormHorizontal>
      <FormHorizontal name="pay" label="Payout">
        <ZodFieldInput name="pay" type="number" />
      </FormHorizontal>
    </FormGroup>
  );
}

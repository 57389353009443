import { useCallback, useState } from 'react';
import { format_money } from '~/components/Money';
import InputCheckbox from '~/components/input/InputCheckbox';
import { ZodNestedForm, useZodFormFieldObject } from '~/components/zod/ZodForm';
import { Boost } from '~/tenants/bre/performable/boost/BreBoostConfig';
import { BreBoostRate } from '~/tenants/bre/performable/boost/BreBoostData';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';
import BreBoostJob from '~/tenants/bre/performable/boost/BreBoostJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnTemplate } from '~/tenants/common/package';

interface BreBoosAddOnProps {
  name: string;
  persona: PersonaType;
}

export function BreBoostAddOn({ name, persona }: BreBoosAddOnProps) {
  const [_name, value, setValue] = useZodFormFieldObject<Partial<Boost>>(name);
  const [showBoost, setShowBoost] = useState(value !== undefined);

  const cost = value?.reach !== undefined
    ? format_money(BreBoostRate[value.reach])
    : undefined;

  const toggleBoost = useCallback((checked: boolean): void => {
    const next = value ?? { };
    setShowBoost(checked);
    setValue(checked ? next : null);
  }, [value]);

  return (
    <TenantPackageAddOnTemplate
      title="Social Media Boosting"
      description="Include social media boosting"
      image="" // ? what should we use here
      persona={persona}
      enable={<InputCheckbox checked={showBoost} onChange={toggleBoost} />}
      cost={cost}
    >
      {showBoost && (
        <ZodNestedForm name={name}>
          <BreBoostFields />
        </ZodNestedForm>
      )}
    </TenantPackageAddOnTemplate>
  );
}

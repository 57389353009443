import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { SignShipping, SignType, shippingSchema, typeSchema } from '~/tenants/bre/performable/print/signs/BreSignsConfig';
import { BreSignsRate } from '~/tenants/bre/performable/print/signs/BreSignsData';
import BreSignsJob from '~/tenants/bre/performable/print/signs/BreSignsJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';

const TYPE_OPTS = typeSchema.options.map<InputRadioCardOption>((value) => ({
  title: BreSignsJob.typeName(value),
  cost: BreSignsRate.signs[value],
  value,
}));

const SHIPPING_OPTS = shippingSchema.options.map<InputRadioCardOption>((value) => ({
  title: BreSignsJob.shippingType(value),
  description: 'delivered',
  cost: BreSignsRate.shipping[value],
  value,
}));

export default function BreSignsForm({ context }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const shipping = useZodFormFieldSingleValue<SignShipping>('shipping');

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type" required>
        <ZodFieldRadioCards name="type" options={TYPE_OPTS} cols={3} />
      </FormHorizontal>
      <FormHorizontal name="shipping" label="Shipping" required>
        <ZodFieldRadioCards name="shipping" options={SHIPPING_OPTS} cols={2} />
      </FormHorizontal>
      <FormHorizontal name={'need_by'} label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => {
            const days = shipping === 'courier' ? 3 : 4;
            return isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), days);
          }}
        />
      </FormHorizontal>
      {shipping === 'courier' && (
        <BreDelivery
          name="delivery"
          label="Delivery"
          context={context}
          required
        />
      )}
    </FormGroup>
  );
}

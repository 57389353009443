import FormGroup from '~/components/form/FormGroup';
import ValibotFieldCheckbox from '~/components/valibot/ValibotFieldCheckbox';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { TenantPerformableSubmitProps } from '~/tenants/common/form';

export default function BreAerialSubmitForm({ context }: TenantPerformableSubmitProps<BreOrderContext>) {
  const hasAerialVideo = context.jobs.some((j) =>
    j.performable_id === 'aerial'
    && (
      j.metadata.type === 'video'
      || j.metadata.type === 'combo'
      || j.metadata.fpv !== undefined
    ),
  );

  if (!hasAerialVideo) {
    return null;
  }
  
  return (
    <FormGroup>
      <label className="flex items-center gap-2" htmlFor="self_edited">
        <ValibotFieldCheckbox id="self_edited" name="self_edited" />
        Self-edited video
      </label>
    </FormGroup>
  );
}

import z from 'zod';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import { datetime } from '~/lib/datettime';
import { TimeZone } from '~/lib/enum';
import { capitalize } from '~/lib/format';
import { DistanceAddressSchema } from '~/lib/model';
import { BRE_DELIVERY_FEES } from '~/tenants/bre/data';

export type NeedBy = 'next_day' | 'same_day';

export type VoiceType = z.infer<typeof voiceSchema>;

export const CONTENT_WRITING_PAY = '50';

export const CONTENT_WRITING_RATE = '75';

export const voiceSchema = z.enum(['male', 'female']);

export const deliverySchema = DistanceAddressSchema.refine(
  ({ time }) => time && deliveryFee(time, 'rate'),
  'Address out of delivery range',
);

export const VOICE_OPTS = voiceSchema.options.map<InputRadioCardOption>((value) => ({
  title: `${capitalize(value)} Voice`,
  description: `We narrate with a ${value} voice`,
  value,
}));

export function toNeedBy(date: TDateISODate | undefined): NeedBy {
  const today = datetime(Date.now(), TimeZone.US_CENTRAL).startOf('day');
  const needBy = date ? datetime(date, TimeZone.US_CENTRAL) : undefined;

  return needBy?.equals(today)
    ? 'same_day'
    : 'next_day';
}

export function deliveryFee(time: number, fee: 'rate' | 'pay') {
  const mins = time / 60;
  const limit = BRE_DELIVERY_FEES.find(({ upTo }) => mins <= upTo);

  return limit?.[fee];
}

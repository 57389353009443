import { FormHorizontal } from '~/components/form/layout';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialData';

interface BreAerialFieldsProps {
  type: BreOrderType;
}

export default function BreAerialFields({ type }: BreAerialFieldsProps) {
  const { COMBO, FIVE, TEN, VIDEO } = BreAerialRate[type];

  return (
    <FormHorizontal name="type" label="Aerial Package" required>
      <ZodFieldRadioCards
        name="type"
        cols={2}
        options={[
          { title: `5 Photos`, cost: FIVE, value: 'five' },
          { title: `10 Photos`, cost: TEN, value: 'ten' },
          { title: `10 Photos + Video`, cost: COMBO, value: 'combo' },
          { title: `Video Only`, cost: VIDEO, value: 'video' },
        ]}
      />
    </FormHorizontal>
  );
}

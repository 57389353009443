import { TenantConfig } from '~/tenants/common/registry';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { DateTime } from 'luxon';
import { holiday_lookup } from '~common/holidays/holidays';
import { TenantPerformableConfig } from '~/tenants/common/TenantJob';
import { v4 } from 'uuid';
import { TenantProvider } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';

export default class TenantWrapper {
  constructor(public config: TenantConfig) {}

  get id() {
    return this.config.id;
  }

  get logo() {
    return this.config.logoVector ?? this.config.logoRaster;
  }

  get name() {
    return this.config.name;
  }

  get providers() {
    return Object.entries(this.config.providers)
      .map(([id, data]) => new this.config.providerClass(this, id, data))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get actions() {
    return this.config.actions ?? [];
  }

  action(id: string) {
    const action = this.actions.find((a) => a.id === id);

    if (!action) {
      throw new Error(`Action with id ${id} not found`);
    }

    return action;
  }

  slideshow(context: TenantOrderContext) {
    for (const performable of Object.values(this.config.performables)) {
      const job = this.job(performable.config, context);

      if (job.deliverable === DeliverableType.SLIDESHOW) {
        return job;
      }
    }

    return null;
  }

  job(
    config: TenantPerformableConfig,
    context: TenantOrderContext,
    metadata?: Record<string, unknown>,
    id = v4(),
    provider?: TenantProvider,
  ) {
    return new this.config.performables[config.id].job(context, id, metadata, provider);
  }

  performable(id: string) {
    const performable = this.config.performables[id];

    if (!performable) {
      throw new Error(`Performable with id ${id} not found for tenant: ${this.config.id}`);
    }

    return performable;
  }

  location(slug: string) {
    const location = this.config.locations.find((l) => l.slug === slug);

    if (!location) {
      throw new Error(`Location with id ${slug} not found`);
    }

    return location;
  }

  provider(id: string) {
    const data = this.config.providers[id];

    if (!data) {
      throw new Error(`Provider with id ${id} not found`);
    }

    return new this.config.providerClass(this, id, data);
  }

  order(context: TenantOrderContext) {
    return new this.config.orderClass(this.config, context);
  }

  isHoliday(date: DateTime) {
    if (!this.config.holidays) {
      return false;
    }

    const holidays = holiday_lookup(date);

    return holidays.some((h) => this.config.holidays?.includes(h));
  }

  form<T extends keyof TenantConfig['forms']>(type: T): TenantConfig['forms'][T] {
    return this.config.forms[type];
  }
}

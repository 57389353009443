import { DateTime } from 'luxon';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import InputAddressSearch from '~/components/input/InputAddress/InputAddressSearch';
import Button from '~/components/interactive/Button';
import { useZodFormFieldObject } from '~/components/zod/ZodForm';
import { DistanceAddress, GeocodedAddress } from '~/lib/model';

interface ZodFieldAddressProps {
  name: string;
  distance?: GeocodedAddress;
  start?: DateTime;
  clearable?: boolean;
}

export default function ZodFieldAddress(props: ZodFieldAddressProps) {
  const [name, value, setValue] = useZodFormFieldObject<DistanceAddress>(props.name);

  let content = (
    <InputAddressSearch
      distance={props.distance}
      start={props.start}
      onChange={setValue}
    />
  );

  if (value !== undefined && value.long) {
    content = (
      <div className="flex gap-2 items-center pt-2">
        <span>{value.line1}</span>
        {props.clearable && (
          <Button
            type="button"
            variant="transparent"
            icon={<IconDelete />}
            onClick={(e) => {
              e.preventDefault();
              setValue(null);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <input type="hidden" name={`${name}.lat`} value={value?.lat ?? ''} />
      <input type="hidden" name={`${name}.long`} value={value?.long ?? ''} />
      <input type="hidden" name={`${name}.line1`} value={value?.line1 ?? ''} />
      {value?.line2 && <input type="hidden" name={`${name}.line2`} value={value.line2} />}
      <input type="hidden" name={`${name}.state`} value={value?.state ?? ''} />
      <input type="hidden" name={`${name}.city`} value={value?.city ?? ''} />
      <input type="hidden" name={`${name}.zip`} value={value?.zip ?? ''} />
      {props.distance && (
        <>
          <input type="hidden" name={`${name}.distance`} value={value?.distance ?? ''} />
          <input type="hidden" name={`${name}.time`} value={value?.time ?? ''} />
        </>
      )}

      {content}
    </>
  );
}

import {
  FORM_CLASS_FOCUS,
  FORM_CLASS_INPUT_BG,
  FORM_CLASS_INPUT_BG_DISABLED,
  FORM_CLASS_INPUT_BG_ENABLED,
  FORM_CLASS_INPUT_TEXT,
  FORM_CLASS_INPUT_TEXT_DISABLED,
  FORM_CLASS_INPUT_TEXT_ENABLED,
  FORM_CLASS_INVALID,
} from './common';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface InputTextareaProps {
  value?: string;
  valid?: boolean;
  name?: string;
  disabled?: boolean;
  icon?: ReactNode;
  autoFocus?: boolean;
  rows?: number;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  label?: string;
}

export default function InputTextarea(props: InputTextareaProps) {
  return (
    <textarea
      value={props.value}
      name={props.name}
      defaultValue={props.defaultValue}
      autoComplete="off"
      autoFocus={props.autoFocus}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      rows={props.rows}
      disabled={props.disabled}
      onBlur={props.onBlur}
      onInput={(e) => {
        const stringValue =
          typeof e?.currentTarget?.value === 'string' && e.currentTarget.value.length > 0
            ? e.currentTarget.value
            : null;

        props.onChange && (!stringValue || stringValue !== props.value) && props.onChange(stringValue);
      }}
      className={classNames(
        FORM_CLASS_INPUT_BG,
        FORM_CLASS_INPUT_TEXT,
        'placeholder-gray-400 shadow-sm flex-1 block min-w-0 text-sm form-textarea',
        {
          [FORM_CLASS_INPUT_BG_DISABLED]: props.disabled,
          [FORM_CLASS_INPUT_BG_ENABLED]: !props.disabled,
          [FORM_CLASS_INPUT_TEXT_DISABLED]: props.disabled,
          [FORM_CLASS_INPUT_TEXT_ENABLED]: !props.disabled,
          [FORM_CLASS_FOCUS]: !props.disabled,
          [FORM_CLASS_INVALID]: props.valid === false,
          'pt-6': !!props.label,
          //'pl-10': !!props.icon,
        },
      )}
    />
  );
}

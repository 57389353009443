import React, { useState } from 'react';
import { TenantActionFormProps } from '~/tenants/common/form';
import { DeliverableType } from '~common/model/Deliverable';
import DeliverableThumbnail, { DeliverableThumbnailGrid } from '~/components/deliverable/DeliverableThumbnail';
import Heading from '~/components/layout/Heading';
import Sections, { Row } from '~/components/layout/Sections';
import Button from '~/components/interactive/Button';
import { useZodFormFieldArray, useZodFormFieldSingleValue, ZodNestedForm } from '~/components/zod/ZodForm';
import ZodSubmitButton from '~/components/zod/ZodSubmitButton';
import { PerformableFormRevenue } from '~/components/performable/PerformableForm';
import Card from '~/components/layout/Card';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TWT_EDITING_LABELS, TwtEditingType } from '~/tenants/twt/performable/editing/TwtEditingConfig';
import { TWT_EDITING_PRICES } from '~/tenants/twt/performable/editing/TwtEditingJob';
import { format_money } from '~/components/Money';

export function Selected({
  deliverables,
}: {
  deliverables: TenantActionFormProps<DeliverableType.PHOTO>['deliverables'];
}) {
  const id = useZodFormFieldSingleValue('deliverable_id');
  const deliverable = deliverables.find((d) => d.id === id);

  if (!deliverable) {
    return <></>;
  }

  return (
    <Card>
      <Row>
        <DeliverableThumbnail deliverable={deliverable} />

        <div className="flex-1">
          <FormGroup>
            <FormHorizontal label="Edit Type">
              <ZodFieldSelect
                name="type"
                options={Object.entries(TWT_EDITING_LABELS).map(([value, label]) => ({
                  value,
                  label: `${label} ${
                    TWT_EDITING_PRICES[value as TwtEditingType]
                      ? format_money(TWT_EDITING_PRICES[value as TwtEditingType])
                      : '(No Charge)'
                  }`,
                }))}
              />
            </FormHorizontal>
            <FormHorizontal label="Notes">
              <ZodFieldTextarea name="notes" />
            </FormHorizontal>
          </FormGroup>

          <ZodFieldHidden name="deliverable_id" />
        </div>
      </Row>
    </Card>
  );
}

export default function TwtEditingAction(props: TenantActionFormProps<DeliverableType.PHOTO>) {
  const [checked, setChecked] = useState<string[]>([]);
  const [configure, setConfigure] = useState(false);
  const [items, add, clear] = useZodFormFieldArray('images');

  if (configure) {
    return (
      <Sections>
        <Row between breakpoint="sm">
          <Heading title="Editing Options" subtitle="Please choose your editing options for each photo." />
          <ZodSubmitButton variant="tenant">
            Submit for <PerformableFormRevenue />
          </ZodSubmitButton>
        </Row>
        {items.map((item) => (
          <ZodNestedForm key={item.name} name={item.name}>
            <Selected deliverables={props.deliverables} />
          </ZodNestedForm>
        ))}
      </Sections>
    );
  }

  return (
    <Sections>
      <Row between breakpoint="sm">
        <Heading title={`Photos Selected: ${checked.length}`} subtitle="Please select at least one photo to edit." />
        <Button
          disabled={checked.length === 0}
          variant="tenant"
          onClick={() => {
            clear();

            for (const id of checked) {
              add({ deliverable_id: id });
            }

            setConfigure(true);
          }}
        >
          Continue
        </Button>
      </Row>
      <DeliverableThumbnailGrid>
        {props.deliverables.map((photo) => (
          <DeliverableThumbnail
            key={photo.id}
            lightbox
            deliverable={photo}
            checked={checked.includes(photo.id)}
            onCheck={(enabled) => {
              if (enabled) {
                setChecked([...checked, photo.id]);
              } else {
                setChecked(checked.filter((id) => id !== photo.id));
              }
            }}
          />
        ))}
      </DeliverableThumbnailGrid>
    </Sections>
  );
}

import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';
import { DeliverableType } from '~common/model/Deliverable';

const BaseRates = {
  EXTRA_EDIT: '125',
  EXTRA_POSE: '105',
  ONSITE: '475',
  STUDIO: '300',
}

export const BrePortraitsRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BrePortraitsJob extends BreJob<typeof BrePortraitsConfig> {
  get performable() {
    return BrePortraitsConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BrePortraitsConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'studio',
    };
  }

  // TODO: Waiting for times from Chris
  onsite(): number {
    return 0;
  }

  revenueLines(): TenantJobLine[] {
    const { EXTRA_EDIT, EXTRA_POSE, ONSITE, STUDIO } = BrePortraitsRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    match(this.metadata.type)
      .with('studio', () => {
        lines.push({
          id: 'headshots',
          description: 'In Studio Photo Shoot',
          amount: new Big(STUDIO),
        });
      })
      .with('onsite', () => {
        lines.push({
          id: 'headshots',
          description: 'Lifestyle Photo Session',
          amount: new Big(ONSITE),
        });
      })
      .exhaustive();

    if (this.metadata.extra_edit) {
      lines.push({
        id: 'extra_edit',
        description: 'Addtitional Edited Photo',
        amount: new Big(EXTRA_EDIT),
      });
    }

    if (this.metadata.extra_pose) {
      lines.push({
        id: 'extra_pose',
        description: 'Additional Lifestyle Photo',
        amount: new Big(EXTRA_POSE),
      });
    }

    return lines;
  }
}

import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { DeliverableType } from '~common/model/Deliverable';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

const BaseRates = {
  OUTDOOR: '200',
  OUTDOOR_XL: '400',
};

export const BreMatterportRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    OUTDOOR: '750',
    OUTDOOR_XL: '1250',
  },
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
}

export default class BreMatterportJob extends BreJob<typeof BreMatterportConfig> {
  get performable() {
    return BreMatterportConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.MATTERPORT;
  }

  get submission(): boolean {
    return true;
  }

  get sqft(): number {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      return this.metadata.sqft ?? this.order.metadata.sqft;
    }

    return 0;
  }

  static scanRevenue(sqft: number): Big {
    return new Big('0.06').times(sqft).plus(220.5);
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreMatterportConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  // TODO: Waiting onsite times from Chris
  onsite(): number {
    return 0;
  }

  revenueLines(): TenantOrderLine[] {
    const { OUTDOOR, OUTDOOR_XL } = BreMatterportRate[this.order.metadata.type];
    const lines: TenantOrderLine[] = [];

    if (this.order.metadata.type === BreOrderType.RESIDENTIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: '3D Scan',
          amount: BreMatterportJob.scanRevenue(this.sqft),
        });

        if (this.metadata.outdoor_front) {
          lines.push({
            id: 'outdoor_front',
            description: `Exteriors (frontyard)`,
            amount: new Big(this.sqft < 8000 ? OUTDOOR : OUTDOOR_XL), 
          });
        }
  
        if (this.metadata.outdoor_back) {
          lines.push({
            id: 'outdoor_back',
            description: `Exteriors (backyard)`,
            amount: new Big(this.sqft < 8000 ? OUTDOOR : OUTDOOR_XL), 
          });
        }
      }
    }

    if (this.order.metadata.type === BreOrderType.COMMERCIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: `3D scan (${this.metadata.plan})`,
          amount: new Big(this.metadata.plan === 'basic' ? '0.07' : '0.09').times(this.sqft),
        });

        if (this.metadata.outdoor_commercial) {
          lines.push({
            id: 'outdoor_commercial',
            description: `Outdoor Matterport (${this.metadata.plan})`,
            amount: new Big(this.metadata.plan === 'basic' ? OUTDOOR : OUTDOOR_XL),
          });
        }
      }
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    if (this.order.metadata.type === BreOrderType.RESIDENTIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: '3D Scan',
          amount: new Big('0.04').times(this.sqft).plus('135'),
        });

        if (this.metadata.outdoor_front) {
          const charge = this.metadata.outdoor_back ? '150' : '125';

          lines.push({
            id: 'outdoor_front',
            description: `Exteriors (frontyard)`,
            amount: new Big(this.sqft < 8000 ? charge : '300'), 
          });
        }
  
        if (this.metadata.outdoor_back) {
          const charge = this.metadata.outdoor_front ? '150' : '125';

          lines.push({
            id: 'outdoor_back',
            description: `Exteriors (backyard)`,
            amount: new Big(this.sqft < 8000 ? charge : '300'), 
          });
        }
      }
    }

    if (this.order.metadata.type === BreOrderType.COMMERCIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: `3D Scan (${this.metadata.plan})`,
          amount: new Big(this.metadata.plan === 'basic' ? '0.05' : '0.07').times(this.sqft),
        });

        if (this.metadata.outdoor_commercial) {
          lines.push({
            id: 'outdoor_commercial',
            description: `Outdoor Matterport (${this.metadata.plan})`,
            amount: new Big(this.metadata.plan === 'basic' ? '600' : '1000'),
          });
        }
      }
    }

    return lines;
  }
}

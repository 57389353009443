import { format_money } from '~/components/Money';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BrePortraitsRate } from '~/tenants/bre/performable/portraits/BrePortraitsJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BrePortraitsForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { EXTRA_EDIT, EXTRA_POSE, ONSITE, STUDIO } = BrePortraitsRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Portraits Package" required>
        <ZodFieldRadioCards
          name="type"
          cols={2}
          options={[
            { title: 'Headshot', description: 'At our Los Gatos Office', cost: STUDIO, value: 'studio' },
            { title: 'Lifestyle', description: 'Within a 25 miles radius of Los Gatos', cost: ONSITE, value: 'onsite' },
          ]}
        />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="extra_edit"
          title="Addtitional Edited Photo"
          description="Add an extra edited photo"
          image="" // ? what image should we use here
          persona={persona}
          cost={EXTRA_EDIT}
        />
        <TenantPackageAddOnCheckbox
          name="extra_pose"
          title="Additional Lifestyle Photo"
          description="Add an extra lifestyle photo"
          image="" // ? what image should we use here
          persona={persona}
          cost={EXTRA_POSE}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

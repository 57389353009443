import BreJob from '~/tenants/bre/model/BreJob';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { DeliverableType } from '~common/model/Deliverable';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BreSocialRate } from '~/tenants/bre/performable/social/BreSocialData';
import { BreBoostRate } from '~/tenants/bre/performable/boost/BreBoostData';

export default class BreSocialJob extends BreJob<typeof BreSocialConfig> {
  get performable() {
    return BreSocialConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get submission(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.SOCIAL;
  }

  get editing(): boolean {
    return !this.metadata.self_edited;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.VIDEO,
    };
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreSocialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  // TODO: Waiting onsite times from Chris
  onsite(): number {
    const { intro, kwasi, narration } = this.metadata;
    const voice = intro || narration ? 60 : 45;
    const upgrade = kwasi ? 30 : 0;

    return voice + upgrade;
  }

  revenueLines(): TenantJobLine[] {
    const { aerial, boost, intro, kwasi, narration, rush, twilight } = this.metadata;
    const { AERIAL, BASE_VIDEO, INTRO, KWASI, NARRATION, RUSH, TWILIGHT } = BreSocialRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'social_video',
      description: 'Basic: Vertical Ground Video Footage',
      amount: new Big(BASE_VIDEO),
      discountable: true,
    });

    if (this.matchSocialPackage) {
      lines.push({
        id: 'social_package',
        description: 'Social Media Package Video Discount',
        amount: new Big(BASE_VIDEO).times('0.1').neg(),
        discount: true,
      });
    }

    if (aerial) {
      lines.push({
        id: 'aerial',
        description: 'Aerial upgrade',
        amount: new Big(AERIAL),
      });
    }

    if (intro) {
      lines.push({
        id: 'intro',
        description: 'Agent Intro',
        amount: new Big(INTRO),
      });
    }

    if (narration) {
      lines.push({
        id: 'narration',
        description: 'Agent Narration',
        amount: new Big(NARRATION),
      });

      if (kwasi) {
        lines.push({
          id: 'kwasi',
          description: `Professional Voice-Over (${kwasi})`,
          amount: new Big(KWASI),
        });
      }
    }

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Upgrade',
        amount: new Big(TWILIGHT),
      });
    }

    if (boost) {
      const rate = BreBoostRate[boost.reach];

      lines.push({
        id: 'social_boost',
        description: `Social media boost (${boost.reach} reach)`,
        amount: new Big(rate),
      });
    }

    if (rush) {
      lines.push({
        id: 'rush_fee',
        description: 'Next-day rush fee',
        amount: new Big(RUSH),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { aerial, intro, kwasi, narration, self_edited, twilight } = this.metadata;
    const { INTRO, NARRATION } = BreSocialRate[this.order.metadata.type];
    const videoSplit = self_edited ? 0.5 : 0.7;
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'social_video',
      description: 'Basic: Vertical Ground Video Footage',
      amount: new Big('300').times(videoSplit).times(this.matchSocialPackage ? '0.9' : '1'),
    });

    if (aerial) {
      lines.push({
        id: 'aerial',
        description: 'Aerial Upgrade',
        amount: new Big('250').times(videoSplit),
      });
    }

    if (intro) {
      lines.push({
        id: 'intro',
        description: 'Agent Intro',
        amount: new Big(INTRO).times(videoSplit),
      });
    }
  
    if (narration) {
      lines.push({
        id: 'narration',
        description: 'Agent Narration',
        amount: new Big(NARRATION).times(videoSplit),
      });

      if (kwasi) {
        lines.push({
          id: 'kwasi',
          description: `Professional Voice-Over (${kwasi})`,
          amount: new Big('75'),
        });
      }
    }

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Upgrade',
        amount: new Big('195'),
      });
    }

    return lines;
  }
}

import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ValibotFieldInput from '~/components/valibot/ValibotFieldInput';

export default function BreMatterportSubmitForm() {
  return (
    <FormGroup>
      <FormHorizontal name="sqft" label="Actual Square Feet">
        <ValibotFieldInput name="sqft" type="number" />
      </FormHorizontal>
    </FormGroup>
  );
}

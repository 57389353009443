import z from 'zod';
import dedent from 'dedent';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';

export type Reach = z.infer<typeof reachSchema>;

export type Boost = z.infer<typeof boostSchema>;

export const reachSchema = z.enum([
  '3,000',
  '5,000',
  '7,000',
  '9,250',
  '11,500',
  '14,000',
  '16,500',
  '20,000',
  '24,000',
]);

export const boostSchema = z.object({
  reach: reachSchema,
  start_at: zodTDateISODate(),
});

export default createPerformableConfig(
  'boost',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }).merge(boostSchema),
  },
  {
    name: 'Social Media Boosting',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: dedent`
      Boost your listing on Facebook and Instagram for instant exposure in the \
      property’s city or any other area of your choice. Share photos, tours, and \
      details interactively, with the potential to go viral. Increase spending to \
      expand your reach.
    `,
    images: [],
  },
);

import { TenantPerformableFormProps } from '~/tenants/common/form';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { CONTENT_WRITING_RATE, toNeedBy } from '~/tenants/bre/performable/common';
import { BrochurePaper, BrochureSide, countSchema, paperSchema, sideSchema } from '~/tenants/bre/performable/print/brochures/BreBrochuresConfig';
import { BreBrochuresRate } from '~/tenants/bre/performable/print/brochures/BreBrochuresData';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRadioCards } from '~/tenants/common/package';
import BreBrochuresJob from '~/tenants/bre/performable/print/brochures/BreBrochuresJob';
import { useTz } from '~/components/hooks/useTz';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { plusBusinessDays } from '~/lib/datettime';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import { capitalize } from '~/lib/format';

const SIDE_OPTS = sideSchema.options.map<InputRadioCardOption>((value) => ({
  title: `${value} Pages`,
  description: `brochures`,
  value,
}));

const COUNT_OPTS = countSchema.options.map<InputRadioCardOption>((value) => ({
  title: `${value} pieces`,
  description: 'print',
  value,
}));

export default function BreBrochuresForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const sides = useZodFormFieldSingleValue<BrochureSide>('sides') ?? '8';
  const paper = useZodFormFieldSingleValue<BrochurePaper>('paper');
  const needBy = useZodFormFieldSingleValue('need_by', toNeedBy);

  const isNotFreeUv = sides === '8' && paper === 'heavy_low_gloss_uv';

  const paperOption = paperSchema.options
    .filter((value) => BreBrochuresRate[needBy][sides]?.[value])
    .map<InputSelectOption>((value) => ({
      label: BreBrochuresJob.paperType({ paper: value, sides }),
      value,
    }));

    const designOptions = layoutSchema.options.map<InputRadioCardOption>((value) => {
      const rate = BreDesignRate[value][`brochure_${sides}`];
  
      return {
        title: `${capitalize(value)} Layout`,
        cost: rate,
        disabled: !rate,
        value,
      };
    });

  return (
    <FormGroup>
      <FormHorizontal name="sides" label="Sides" required>
        <ZodFieldRadioCards name="sides" options={SIDE_OPTS} />
      </FormHorizontal>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldRadioCards name="count" options={COUNT_OPTS} cols={2} />
      </FormHorizontal>
      <FormHorizontal name="paper" label="Paper" required>
        <ZodFieldSelect name="paper" options={paperOption} />
      </FormHorizontal>
      {!isNotFreeUv && paper !== 'heavy_low_gloss' && (
        <FormHorizontal name="no_free_uv" label="UV Cover">
          <label className="flex gap-2 cursor-pointer" htmlFor="no_free_uv">
            <ZodFieldCheckbox id="no_free_uv" name="no_free_uv" />
            Opt-out of free UV cover
          </label>
        </FormHorizontal>
      )}
      <FormHorizontal name={'need_by'} label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => date >= plusBusinessDays(tz().startOf('day'), 2)}
        />
      </FormHorizontal>
      <BreDelivery
        name="delivery"
        label="Delivery"
        context={context}
        required
      />

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="content_writing"
          title="Content writing"
          description="Add content writing for all of your marketing pieces"
          image="" // ? what image should we use here
          persona={persona}
          cost={CONTENT_WRITING_RATE}
        />
        <TenantPackageAddOnRadioCards
          name="design"
          title="Design"
          description="Include layout design to your flyers"
          image="" // ? what image should we use here
          persona={persona}
          options={designOptions}
          cols={2}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import TwtTwilightConfig, { TwtTwilightType } from '~/tenants/twt/performable/twilight/TwtTwilightConfig';
import { TWT_PHOTO_TWILIGHT } from '~/tenants/twt/performable/photo/TwtPhotoJob';

export const TWT_TWILIGHT_PRICE = 249;

export class TwtTwilightJob extends TwtJob<typeof TwtTwilightConfig> {
  get performable() {
    return TwtTwilightConfig;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  info(): DisplayData[] {
    const info = super.info();

    info.push({
      name: 'Type',
      value:
        this.metadata.type === TwtTwilightType.FRONT
          ? 'Front'
          : this.metadata.type === TwtTwilightType.BACK
          ? 'Back'
          : 'Front/Back',
    });

    return info;
  }

  onsite() {
    return 30;
  }

  get short() {
    return 'NIGHT';
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtTwilightConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtTwilightConfig.schema),
    } as any;
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.GALLERY };
  }

  get configurable() {
    return true;
  }

  get eligible() {
    return !this.order.jobs.some((j) => j.performable_id === 'photo' && j.metadata.twilight);
  }

  expenseLines(): TenantOrderLine[] {
    const merged = this.appointment?.jobs.some((j) => j.performable_id === 'photo');

    return [
      {
        amount: new Big(merged ? 150 : 190),
        description: merged ? 'Twilight Shoot' : 'Up to 10 Photos',
        id: 'photos',
      },
    ];
  }

  revenueLines(): TenantOrderLine[] {
    const merged = this.appointment?.jobs.some((j) => j.performable_id === 'photo');

    return [
      {
        amount: new Big(merged ? TWT_PHOTO_TWILIGHT : TWT_TWILIGHT_PRICE),
        taxable: true,
        phase: 'delivered',
        description: merged ? 'Twilight Shoot' : 'Up to 10 Photos',
        id: 'photos',
      },
    ];
  }
}

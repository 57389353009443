import { DesignLayout, DesignType } from '~/tenants/bre/performable/print/design/BreDesignConfig';

type TypeMap = Record<DesignType, string>;

type LayoutMap = Record<DesignLayout, Partial<TypeMap>>;

export const BreDesignRate: LayoutMap = {
  new: {
    postcard_half: '120',
    flyer_1: '85',
    flyer_2: '120',
    flyer_4: '240',
    brochure_8: '480',
    brochure_12: '575',
    brochure_16: '670',
    brochure_20: '670',
  },
  existing: {
    postcard_half: '60',
    flyer_1: '60',
    flyer_2: '85',
    flyer_4: '120',
    brochure_8: '240',
    brochure_12: '350',
    brochure_16: '400',
    brochure_20: '400',
  },
};

export const BreDesignPay: LayoutMap = {
  new: {
    postcard_half: '95',
    flyer_1: '75',
    flyer_2: '95',
    flyer_4: '190',
    brochure_8: '380',
    brochure_12: '475',
    brochure_16: '570',
    brochure_20: '570',
  },
  existing: {
    postcard_half: '47.50',
    flyer_1: '47.50',
    flyer_2: '75',
    flyer_4: '95',
    brochure_8: '190',
    brochure_12: '250',
    brochure_16: '300',
    brochure_20: '300',
  },
};

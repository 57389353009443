import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, coerceOptionalPositiveNumber, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.coerce.number().positive(),
      extra_photos: coerceOptionalPositiveNumber(),
      max_extra_photos: coerceOptionalPositiveNumber(),
      notes: z.string().optional(),
      copyright: coerceBoolean().optional(),
      twilight: coerceBoolean().optional(),
      local_area: z.array(z.object({ name: z.string(), location: z.string() })).optional(),
      rush: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Photography Day & Twilight',
    group: 'Listing',
    images: [],
    short: '',
    tenant: TenantId.BEYOND_RE_MARKETING,
  },
);

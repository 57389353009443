import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { CONTENT_WRITING_PAY, CONTENT_WRITING_RATE, NeedBy, deliveryFee, toNeedBy } from '~/tenants/bre/performable/common';
import { BreDesignPay, BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import BreFlyersConfig, { FlyerPaper, FlyerSide } from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';
import { BreFlyersPay, BreFlyersRate } from '~/tenants/bre/performable/print/flyers/BreFlyersData';
import { DeliverableType } from '~common/model/Deliverable';

interface PaperTypeOptions {
  paper: FlyerPaper;
  sides: FlyerSide;
  no_free_uv?: boolean;
}

export default class BreFlyersJob extends BreJob<typeof BreFlyersConfig> {
  get performable() {
    return BreFlyersConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get paperType(): string {
    const { paper, sides, no_free_uv } = this.metadata;
    return BreFlyersJob.paperType({ paper, sides, no_free_uv });
  }

  get needBy(): NeedBy {
    const { need_by } = this.metadata;
    return toNeedBy(need_by);
  }

  get sidesText(): string {
    const { sides } = this.metadata;
    return BreFlyersJob.sidesText(sides);
  }

  static paperType({ paper, sides, no_free_uv }: PaperTypeOptions): string {
    const withUv = match(sides)
      .with('4', () => !no_free_uv ? ' with free UV cover' : '')
      .otherwise(() => ' with UV cover');

    return match(paper)
      .with('low_gloss', () => 'Low-Gloss')
      .with('heavy_gloss', () => 'Heavy-Gloss')
      .with('heavy_gloss_uv', () => `Heavy-gloss${withUv}`)
      .with('super_heavy_gloss_uv', () => `Super heavy-gloss${withUv}`)
      .with('linen_cover', () => 'Linen cover')
      .exhaustive()
  }

  static sidesText(sides: FlyerSide): string {
    return match(sides)
      .with('1', (one) => `${one} Page`)
      .otherwise(() => `${sides} Pages`);
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreFlyersConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      sides: '1',
      count: '50',
      paper: 'low_gloss',
      need_by: '' as TDateISODate,
      delivery: {
        city: '',
        distance: null,
        lat: 0,
        line1: '',
        long: 0,
        state: '',
        time: null,
        zip: '',
      },
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count, paper, sides, need_by, delivery, content_writing, design } = this.metadata;
    const lines: TenantJobLine[] = [];
    
    if (need_by) {
      const nextdayRate = BreFlyersRate.next_day[sides]?.[paper]?.[count];

      if (nextdayRate) {
        const samedayRate = BreFlyersRate.same_day[sides]?.[paper]?.[count];

        lines.push({
          id: 'flyers_print',
          description: `${this.sidesText} ${this.paperType} (x${count})`,
          amount: new Big(nextdayRate),
        });

        if (this.needBy === 'same_day' && samedayRate) {
          lines.push({
            id: 'flyers_same_day_fee',
            description: "Same-day print fee",
            amount: new Big(samedayRate).minus(nextdayRate),
          })
        }
      }
    }

    if (delivery.time !== null) {
      const rate = deliveryFee(delivery.time, 'rate');

      if (rate) {
        lines.push({
          id: 'delivery_fee',
          description: `Delivery Fee (${delivery.city})`,
          amount: new Big(rate),
        });
      }
    }

    if (content_writing) {
      lines.push({
        id: 'content_writing',
        description: 'Content Writing',
        amount: new Big(CONTENT_WRITING_RATE),
      });
    }

    if (design) {
      const rate = BreDesignRate[design][`flyer_${sides}`];

      if (rate) {
        lines.push({
          id: 'flyer_design',
          description: `Flyer design (${design} layout)`,
          amount: new Big(rate),
        });
      }
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count, paper, sides, need_by, delivery, content_writing, design } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (need_by) {
      const nextdayPay = BreFlyersPay.next_day[sides]?.[paper]?.[count];

      if (nextdayPay) {
        const samedayPay = BreFlyersPay.same_day[sides]?.[paper]?.[count];

        lines.push({
          id: 'flyers_print',
          description: `${this.sidesText} ${this.paperType} (x${count})`,
          amount: new Big(nextdayPay),
        });

        if (this.needBy === 'same_day' && samedayPay) {
          lines.push({
            id: 'flyers_same_day_fee',
            description: "Same-day print fee",
            amount: new Big(samedayPay).minus(nextdayPay),
          })
        }
      }
    }

    if (delivery.time !== null) {
      const pay = deliveryFee(delivery.time, 'pay');

      if (pay) {
        lines.push({
          id: 'delivery_fee',
          description: `Delivery Fee (${delivery.city})`,
          amount: new Big(pay),
        });
      }
    }

    if (content_writing) {
      lines.push({
        id: 'content_writing',
        description: 'Content Writing',
        amount: new Big(CONTENT_WRITING_PAY),
      });
    }

    if (design) {
      const pay = BreDesignPay[design][`flyer_${sides}`];

      if (pay) {
        lines.push({
          id: 'flyer_design',
          description: `Flyer design (${design} layout)`,
          amount: new Big(pay),
        });
      }
    }

    return lines;
  }
}

import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreGuidesConfig, { GuideType } from '~/tenants/bre/performable/print/guides/BreGuidesConfig';
import { BreGuidesRate } from '~/tenants/bre/performable/print/guides/BreGuidesData';

export class BreGuidesJob extends BreJob<typeof BreGuidesConfig> {
  get performable() {
    return BreGuidesConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get typeText(): string {
    const { type } = this.metadata;
    return BreGuidesJob.typeText(type);
  }

  static typeText(type: GuideType): string {
    return match(type)
      .with('selling', () => "Selling")
      .with('buyers', () => 'Buyers')
      .with('after_sale', () => 'After the Sale')
      .with('preparing_for_sale', () => 'Preparing Your Home for Sale')
      .with('contract_to_close', () => 'Contract to Close')
      .exhaustive();
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreGuidesConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'selling',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { PDF } = BreGuidesRate;
    const lines: TenantJobLine[] = [];

    if (PDF) {
      lines.push({
        id: 'guides_pdf',
        description: `${this.typeText} Guide`,
        amount: new Big(PDF),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { PDF } = BreGuidesRate;
    const lines: TenantJobLine[] = [];

    if (PDF) {
      lines.push({
        id: 'guides_pdf',
        description: `${this.typeText} Guide`,
        amount: new Big(PDF).times('0.85'),
      });
    }

    return lines;
  }
}

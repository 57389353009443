import {
  autoPlacement,
  computePosition,
  ComputePositionConfig,
  flip,
  FloatingOverlay,
  shift,
  size,
} from '@floating-ui/react-dom-interactions';
import { ForwardedRef, ReactElement, ReactNode, Ref, RefObject, useRef } from 'react';
import { State, useHookstate } from '@hookstate/core';
import { Portal } from '~/components/interactive/Portals';

export interface PopupProps {
  children: ReactElement;
  activator?: ReactNode;
  strategy?: 'bottom' | 'best' | 'bottom-left';
  open?: boolean | State<boolean>;
  reference?: Ref<ReactNode> | HTMLDivElement | ForwardedRef<HTMLDivElement>;
  onClose?: () => void;
  className?: string;
  lightbox?: boolean;
  rerender?: string;
  autowidth?: boolean;
  offset?: number;
  hover?: number | true;
}

export default function Popup({
  activator,
  children,
  open,
  reference,
  onClose,
  lightbox = false,
  autowidth = false,
  strategy = 'best',
}: PopupProps) {
  const scope = useHookstate(open || false);
  const isOpen = typeof open === 'boolean' ? open : !activator && typeof open === 'undefined' ? true : scope.get();
  const calculated = useHookstate(false);

  const doClose = () => {
    calculated.set(false);

    if (typeof open === 'boolean' || typeof open === 'undefined') {
      if (onClose) {
        onClose();
      }
    } else {
      scope.set(false);
    }
  };

  const ref = useRef<HTMLDivElement>();
  const activatorRef = useRef<HTMLDivElement>(null);

  const calculate = () => {
    const target = reference
      ? reference instanceof HTMLDivElement
        ? HTMLDivElement
        : (reference as RefObject<any>).current
      : activatorRef.current;

    if (!ref.current || !target || !(target instanceof Element)) {
      return;
    }

    let options: Partial<ComputePositionConfig> = {
      middleware: [
        autoPlacement({
          allowedPlacements: [
            'left',
            'right',
            'top',
            'bottom',
          ],
        }),
        shift(),
      ],
    };

    if (strategy === 'bottom') {
      options = {
        placement: 'bottom',
        middleware: [
          flip(),
        ],
      };
    } else if (strategy === 'bottom-left') {
      options = {
        placement: 'bottom-start',
        middleware: [
          flip(),
        ],
      };
    }

    if (autowidth) {
      options.middleware?.push(
        size({
          apply({ rects }) {
            if (ref.current) {
              Object.assign(ref.current.style, {
                width: `${rects.reference.width}px`,
              });
            }
          },
        }),
      );
    }

    computePosition(target, ref.current, options).then(({ x, y }) => {
      if (ref.current) {
        Object.assign(ref.current.style, {
          top: `${y}px`,
          left: `${x}px`,
        });
      }
    });
  };

  let center = '';

  if (!activator && !reference) {
    center = 'flex items-center justify-center';
  }

  const overlay = useRef<HTMLDivElement>(null);

  const portal = isOpen && (
    <Portal>
      <FloatingOverlay
        ref={overlay}
        className={`${lightbox ? 'bg-black bg-opacity-25' : 'pointer-events-none'} ${center} z-100 select-none`}
        onClick={
          lightbox
            ? (e) => {
                if (e.target === overlay.current) {
                  e.stopPropagation();

                  // make sure we are not closing if clicking children
                  doClose();
                }
              }
            : undefined
        }
      >
        <div
          ref={(el) => {
            ref.current = el || undefined;

            calculate();
          }}
          className="absolute"
        >
          {children}
        </div>
      </FloatingOverlay>
    </Portal>
  );

  if (activator) {
    return (
      <>
        <div
          ref={activatorRef}
          onMouseEnter={() => scope.set(true)}
          onMouseLeave={() => scope.set(false)}
          onClick={() => scope.set(false)}
        >
          {activator}
        </div>
        {portal}
      </>
    );
  }

  return <>{portal}</>;
}

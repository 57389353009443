import { SignShipping, SignType } from '~/tenants/bre/performable/print/signs/BreSignsConfig';

type TypeMap = Record<SignType, string>;

type ShippingMap = Record<SignShipping, string>;

interface RidersMap {
  signs: TypeMap;
  shipping: ShippingMap;
}

export const BreSignsRate: RidersMap = {
  signs: {
    sign_24x24: '155',
    sign_24x18: '130',
    aframe_24x24: '120',
  },
  shipping: {
    courier: '25',
    ups: '18',
  },
};

export const BreSignsPay: Pick<RidersMap, 'signs'> = {
  signs: {
    sign_24x24: '132',
    sign_24x18: '110',
    aframe_24x24: '105',
  },
};

import { NeedBy } from '~/tenants/bre/performable/common';
import { BrochureCount, BrochurePaper, BrochureSide } from '~/tenants/bre/performable/print/brochures/BreBrochuresConfig';

type CountMap = Record<BrochureCount, string>;

type PaperMap = Record<BrochurePaper, CountMap>;

type SideMap = Record<BrochureSide, Partial<PaperMap>>;

type NeedByMap = Record<NeedBy, Partial<SideMap>>;

export const BreBrochuresRate: NeedByMap = {
  next_day: {
    8: {
      heavy_low_gloss: {
        50: '475',
        100: '535',
        150: '685',
        200: '870',
      },
      heavy_low_gloss_uv: {
        50: '515',
        100: '595',
        150: '750',
        200: '940',
      },
      heavy_heavy_gloss_uv: {
        50: '535',
        100: '715',
        150: '880',
        200: '995',
      },
      super_heavy_heavy_gloss_uv: {
        50: '555',
        100: '725',
        150: '890',
        200: '1015',
      },
    },
    12: {
      heavy_low_gloss_uv: {
        50: '645',
        100: '780',
        150: '1025',
        200: '1255',
      },
      super_heavy_low_gloss_uv: {
        50: '675',
        100: '815',
        150: '1130',
        200: '1370',
      },
    },
    16: {
      heavy_low_gloss_uv: {
        50: '705',
        100: '950',
        150: '1240',
        200: '1470',
      },
      super_heavy_low_gloss_uv: {
        50: '735',
        100: '990',
        150: '1350',
        200: '1690',
      },
    },
    20: {
      heavy_low_gloss_uv: {
        50: '875',
        100: '1220',
        150: '1610',
        200: '1975',
      },
    },
    24: {
      heavy_low_gloss_uv: {
        50: '1010',
        100: '1415',
        150: '1880',
        200: '2055',
      },
    },
    28: {
      heavy_low_gloss_uv: {
        50: '1140',
        100: '1610',
        150: '1940',
        200: '2535',
      },
    },
  },
  same_day: { },
};

export const BreBrochuresPay: NeedByMap = {
  next_day: {
    8: {
      heavy_low_gloss: {
        50: '415',
        100: '465',
        150: '595',
        200: '755',
      },
      heavy_low_gloss_uv: {
        50: '445',
        100: '515',
        150: '650',
        200: '815',
      },
      heavy_heavy_gloss_uv: {
        50: '465',
        100: '620',
        150: '765',
        200: '875',
      },
      super_heavy_heavy_gloss_uv: {
        50: '480',
        100: '630',
        150: '775',
        200: '885',
      },
    },
    12: {
      heavy_low_gloss_uv: {
        50: '560',
        100: '675',
        150: '890',
        200: '1090',
      },
      super_heavy_low_gloss_uv: {
        50: '585',
        100: '710',
        150: '980',
        200: '1190',
      },
    },
    16: {
      heavy_low_gloss_uv: {
        50: '610',
        100: '825',
        150: '1075',
        200: '1275',
      },
      super_heavy_low_gloss_uv: {
        50: '635',
        100: '860',
        150: '1165',
        200: '1455',
      },
    },
    20: {
      heavy_low_gloss_uv: {
        50: '760',
        100: '1060',
        150: '1400',
        200: '1715',
      },
    },
    24: {
      heavy_low_gloss_uv: {
        50: '875',
        100: '1230',
        150: '1635',
        200: '1785',
      },
    },
    28: {
      heavy_low_gloss_uv: {
        50: '990',
        100: '1400',
        150: '1865',
        200: '2205',
      },
    },
  },
  same_day: { },
};

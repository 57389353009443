import { FolderCount } from '~/tenants/bre/performable/print/folders/BreFoldersConfig';

type CountMap = Record<FolderCount, string>;

export const BreFoldersRate: Partial<CountMap> = {
  250: '995',
  500: '1325',
  1000: '1700',
};

export const BreFoldersPay: Partial<CountMap> = {
  250: '865',
  500: '1150',
  1000: '1475',
};

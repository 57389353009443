import { DateTime } from 'luxon';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { isBusinessDay } from '~/lib/datettime';
import { reachSchema } from '~/tenants/bre/performable/boost/BreBoostConfig';

const REACH_OPTS = reachSchema.options.map<InputSelectOption>((value) => ({
  label: `Up to ${value}`,
  value,
}));

export function BreBoostFields() {
  const tz = useTz();

  const today = tz().startOf('day');
  const suggested = today.weekday === 5
    ? today.plus({ week: 1 })
    : today.set({ weekday: 5 });

  return (
    <>
      <FormHorizontal name="reach" label="Potential Reach" required>
        <ZodFieldSelect name="reach" options={REACH_OPTS} />
      </FormHorizontal>
      <FormHorizontal name="start_at" label="Start Date" required>
        <ZodFieldDate
          name="start_at"
          placeholder={suggested.toFormat('MM-dd-yyyy')}
          filter={date => isBusinessDay(date) && date >= today}
        />
      </FormHorizontal>
    </>
  );
}

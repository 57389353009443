import Big from 'big.js';
import { match } from 'ts-pattern';
import { capitalize } from '~/lib/format';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreDesignConfig, { DesignType } from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { BreDesignPay, BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';

export default class BreDesignJob extends BreJob<typeof BreDesignConfig> {
  get performable() {
    return BreDesignConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get typeName(): string {
    const { type } = this.metadata;
    return BreDesignJob.typeName(type);
  }

  static typeName(type: DesignType): string {
    return match(type)
      .with('postcard_half', () => 'Half-sheet Post Card')
      .with('postcard_jumbo', () => 'Jumbo Post Card')
      .with('postcard_flats', () => 'Flat Post Card')
      .with('flyer_1', () => '1 Side Flyer')
      .with('flyer_2', () => '2 Sides Flyer')
      .with('flyer_4', () => '4 Sides Flyer')
      .with('brochure_8', () => '8 Pages Brochure/Booklet')
      .with('brochure_12', () => '12 Pages Brochure/Booklet')
      .with('brochure_16', () => '16 Pages Brochure/Booklet')
      .with('brochure_20', () => '20 Pages Brochure/Booklet')
      .with('brochure_24', () => '24 Pages Brochure/Booklet')
      .with('brochure_28', () => '28 Pages Brochure/Booklet')
      .exhaustive();
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreDesignConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      layout: 'new',
      type: 'postcard_half',
    };
  }

  revenueLines(): TenantJobLine[] {
    const { layout, type } = this.metadata;
    const rate = BreDesignRate[layout][type];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'print_design',
        description: `${capitalize(layout)} ${this.typeName} design`,
        amount: new Big(rate),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { layout, type } = this.metadata;
    const pay = BreDesignPay[layout][type];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'print_design',
        description: `${capitalize(layout)} ${this.typeName} design`,
        amount: new Big(pay),
      });
    }

    return lines;
  }
}

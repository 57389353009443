import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreCinematicAddOns from '~/tenants/bre/performable/cinematic/BreCinematicAddOns';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreCinematicForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { RUSH } = BreCinematicRate[context.metadata.type];
  return (
    <FormGroup>
      {context.metadata.type === BreOrderType.COMMERCIAL && (
        <FormHorizontal name="duration" label="Duration (mins)">
          <ZodFieldInput name="duration" type="number" />
        </FormHorizontal>
      )}

      <TenantPerformableAddOns persona={persona}>
        <BreCinematicAddOns persona={persona} type={context.metadata.type} />
        <TenantPackageAddOnCheckbox
          name="rush"
          title="Rush"
          description="Need your video by the next business day instead of the second business day?"
          image="" // ? what should we use here
          persona={persona}
          cost={RUSH}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

/*
 * Cinematic Style Films will engage potential buyers and bring you new clients in the future! Our new Cinematic Style Films are not just your run-of-the-mill walk-throughs videos anymore!
 *
 * Our footage is stabilized by top-of-the-line handheld gimbals and cameras. Although having the shiniest and the highest quality gear may not be the most important aspect of what we do, having the best tools ensures that our production value is on par with our storytelling ability. Our well-produced and edited Cinematic Videos will engage potential buyers so they will want to see your listing in person.
 *
 * Our videos are edited by a Professional Editing Team and can take anywhere from 48-72 hours from the shoot date depending on the package you choose. There is as much to editing as there is to filming!
 */

/*
Cinematic Style Films will engage potential buyers and bring you new clients in the future! Our new Cinematic Style Films are not just your run-of-the-mill walk-throughs videos anymore!
Just choose if you want to include your video with or without Aerial footage..
 */

import { CoverCount } from '~/tenants/bre/performable/print/covers/BreCoversConfig';

type CountMap = Record<CoverCount, string>;

export const BreCoversRate: Partial<CountMap> = {
  100: '175',
  250: '315',
  500: '385',
  1000: '470',
};

export const BreCoversPay: Partial<CountMap> = {
  100: '150',
  250: '270',
  500: '330',
  1000: '405',
};

import { match } from 'ts-pattern';
import { format_money } from '~/components/Money';
import InputCheckbox from '~/components/input/InputCheckbox';
import { usePerformableGroup } from '~/components/performable/PerformableGroupForm';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { AerialType } from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreMatterportJob from '~/tenants/bre/performable/matterport/BreMatterportJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnTemplate, TenantPackageInclude } from '~/tenants/common/package';

export function BrePackagePhotosInclude() {
  const photos = useZodFormFieldSingleValue('photos');

  return (
    <TenantPackageInclude name="Ground Photography">
      {`${photos} Photos`}
    </TenantPackageInclude>
  );
}

export function BrePackageFloorplanInclude() {
  return (
    <TenantPackageInclude name="2D Floorplan">
      2D floor plan
    </TenantPackageInclude>
  );
}

export function BrePackageSocialInclude() {
  return (
    <TenantPackageInclude name="Social Media Video">
      Basic Ground Footage
    </TenantPackageInclude>
  );
}

export function BrePackageCinematicInclude() {
  return (
    <TenantPackageInclude name="Cinematic Video">
      With a free social media edit
    </TenantPackageInclude>
  )
}

export function BrePackageBoostInclude() {
  const reach = useZodFormFieldSingleValue('reach');

  return (
    <TenantPackageInclude name="Social Media Boost">
      {`Up to ${reach} potential reach`}
    </TenantPackageInclude>
  )
}

export function BrePackageAerialInclude() {
  const type = useZodFormFieldSingleValue<AerialType>('type') ?? 'five';

  const title = match(type)
    .with('five', 'ten', () => 'Aerial Photos')
    .with('combo', () => 'Aerial Photos & Video')
    .with('video', () => 'Aerial Video')
    .exhaustive();

  return (
    <TenantPackageInclude name={title}>
      {match(type)
        .with('five', () => '5 aerial photos')
        .with('ten', () => '10 aerial photos')
        .with('combo', () => '10 aerial photos + aerial video clip')
        .with('video', () => 'aerial video clip')
        .exhaustive()
      }
    </TenantPackageInclude>
  );
}

export function BrePackageWebsiteInclude() {
  return (
    <TenantPackageInclude name="Single Property Website Page">
      1-year subscription to a single property website page
    </TenantPackageInclude>
  );
}

export function BrePackageDuskInclude() {
  return (
    <TenantPackageInclude name="Day-to-Dusk Edit">
      One complimentary Day-to-Dusk photo edit.
    </TenantPackageInclude>
  );
}

export function BrePackageMatterportUpgrade() {
  const { addPerformable, order, removePerformable } = usePerformableGroup<BreOrderContext>();
  
  return (
    <TenantPackageAddOnTemplate
      title="Upgrade 3D"
      description="Upgrade to our 3D Matterport Showcase is an online experience"
      image="" // ? what image should we use here
      persona={PersonaType.CUSTOMER}
      cost={order.metadata.type !== BreOrderType.MARKETING
        ? format_money(BreMatterportJob.scanRevenue(order.metadata.sqft).toFixed(2))
        : undefined
      }
      enable={
        <InputCheckbox
          onChange={checked =>
            checked
              ? addPerformable(BreMatterportConfig)
              : removePerformable(BreMatterportConfig)
          }
        />
      }
    />
  );
}

import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicData';
import { VOICE_OPTS } from '~/tenants/bre/performable/common';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRadioCards } from '~/tenants/common/package';

interface BreCinematicAddOnsProps {
  persona: PersonaType;
  type: BreOrderType;
}

export default function BreCinematicAddOns({ persona, type }: BreCinematicAddOnsProps) {
  const { AERIAL_PHOTOS, INTRO, KWASI, NARRATION, TWILIGHT } = BreCinematicRate[type];
  const narration = useZodFormFieldSingleValue('narration')

  return (
    <>
      <TenantPackageAddOnCheckbox
        name="aerial_upgrade"
        title="Aerial Upgrade"
        description="Adds aerial to the Cinematic Video and 5 aerial photos (Must meet FAA area rules for the address)"
        image="" // ? what should we use here
        persona={persona}
      />
      <TenantPackageAddOnCheckbox
        name="intro"
        title="Agent Intro"
        description="Add agent on camera intro."
        image="" // ? what should we use here
        persona={persona}
        cost={INTRO}
      />
      <TenantPackageAddOnCheckbox
        name="narration"
        title="Agent Narration"
        description="Add agent narration."
        image="" // ? what should we use here
        persona={persona}
        cost={NARRATION}
      />
      {narration === "true" && (
        <TenantPackageAddOnRadioCards
          name="kwasi"
          title="Professional Voice-Over"
          description="Enhance your video with a professional voice-over and script."
          image="" // ? what should we use here
          persona={persona}
          options={VOICE_OPTS}
          cost={KWASI}
          cols={2}
        />
      )}
      <TenantPackageAddOnCheckbox
        name="twilight"
        title="Twilight Transitions"
        description="Day-to-Dusk Twilight Transitions"
        image="" // ? what should we use here
        persona={persona}
        cost={TWILIGHT}
      />
      <TenantPackageAddOnCheckbox
        name="aerial_photos"
        title="5 Aerial Photos"
        description="Add 5 additional photos to the package"
        image="" // ? what should we use here
        persona={persona}
        cost={AERIAL_PHOTOS}
      />
    </>
  );
}

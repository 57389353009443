import {
  FORM_CLASS_FOCUS,
  FORM_CLASS_INPUT_BG,
  FORM_CLASS_INPUT_BG_DISABLED,
  FORM_CLASS_INPUT_BG_ENABLED,
  FORM_CLASS_INPUT_TEXT,
  FORM_CLASS_INPUT_TEXT_DISABLED,
  FORM_CLASS_INPUT_TEXT_ENABLED,
  FORM_CLASS_INVALID,
} from './common';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface InputGenericProps {
  value?: string;
  valid?: boolean;
  name?: string;
  disabled?: boolean;
  icon?: ReactNode;
  autoFocus?: boolean;
  type: HTMLInputElement['type'];
  placeholder?: string;
  onChange?: (value: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  label?: string;
}

export default function InputGeneric(props: InputGenericProps) {
  const input = (
    <>
      <input
        value={props.value}
        name={props.name}
        autoFocus={props.autoFocus}
        onFocus={props.onFocus}
        autoComplete="off"
        placeholder={props.placeholder}
        type={props.type}
        disabled={props.disabled}
        onBlur={props.onBlur}
        onInput={(e) => {
          const stringValue =
            typeof e?.currentTarget?.value === 'string' && e.currentTarget.value.length > 0
              ? e.currentTarget.value
              : null;

          props.onChange && (!stringValue || stringValue !== props.value) && props.onChange(stringValue);
        }}
        className={classNames(
          FORM_CLASS_INPUT_BG,
          FORM_CLASS_INPUT_TEXT,
          'placeholder-gray-400 flex-1 block min-w-0 text-sm form-input',
          {
            [FORM_CLASS_INPUT_BG_DISABLED]: props.disabled,
            [FORM_CLASS_INPUT_BG_ENABLED]: !props.disabled,
            [FORM_CLASS_INPUT_TEXT_DISABLED]: props.disabled,
            [FORM_CLASS_INPUT_TEXT_ENABLED]: !props.disabled,
            [FORM_CLASS_FOCUS]: !props.disabled,
            [FORM_CLASS_INVALID]: props.valid === false,
            'pt-6': !!props.label,
            //'pl-10': !!props.icon,
          },
        )}
      />
      {props.disabled && <input type="hidden" name={props.name} value={props.value} />}
    </>
  );

  //if (!props.icon) {
  if (!props.label) {
    return input;
  }

  return (
    <div className="flex-1 flex items-center justify-center">
      <div className="w-full">
        <div className="relative">
          <span className="absolute text-gray-500 ml-3 mt-2 font-medium text-xs">{props.label}</span>
          {input}
        </div>
      </div>
    </div>
  );
  //}

  // return (
  //   <div class="flex-1 flex items-center justify-center">
  //     <div class="w-full">
  //       <div class="relative">
  //         <div class="absolute inset-y-0 left-0 pl-3 text-gray-400 flex items-center pointer-events-none">
  //           <div class="h-5 w-5">{<Icon type={props.icon} size={ThemeSize.Large} />}</div>
  //         </div>
  //         {input}
  //       </div>
  //     </div>
  //   </div>
  // );
}

import { FIRST_VERSION_TIMESTAMP, coerceBoolean, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';
import z from 'zod';
import dedent from 'dedent';

export default createPerformableConfig(
  'twilight',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.coerce.number().positive(),
      shoot_date: zodTDateISODate(),
      notes: z.string().optional(),
      copyright: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Twilight Separate Photo Shoot',
    group: 'Listing',
    images: [],
    short: dedent`
      Choose this option when you want the property photographed during \
      daylight hours and only want us to return to shoot the front and back of \
      the property at twilight.
    `,
    tenant: TenantId.BEYOND_RE_MARKETING,
  },
);

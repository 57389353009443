import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { VOICE_OPTS } from '~/tenants/bre/performable/common';
import { BreSocialRate } from '~/tenants/bre/performable/social/BreSocialData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRadioCards } from '~/tenants/common/package';

interface BreSocialAddOnsProps {
  persona: PersonaType;
  type: BreOrderType;
}

export default function BreSocialAddOns({ persona, type }: BreSocialAddOnsProps) {
  const { AERIAL, INTRO, KWASI, NARRATION, TWILIGHT } = BreSocialRate[type];
  const narration = useZodFormFieldSingleValue('narration');

  return (
    <>
      <TenantPackageAddOnCheckbox
        name="aerial"
        title="Aerial Upgrade"
        description="Add aerial video & 5 additional aerial photos."
        image="" // ? what should we use here
        persona={persona}
        cost={AERIAL}
      />
      <TenantPackageAddOnCheckbox
        name="intro"
        title="Agent Intro"
        description="Add agent on camera intro."
        image="" // ? what should we use here
        persona={persona}
        cost={INTRO}
      />
      <TenantPackageAddOnCheckbox
        name="narration"
        title="Agent Narration"
        description="Add agent narration."
        image="" // ? what should we use here
        persona={persona}
        cost={NARRATION}
      />
      {narration === "true" && (
        <TenantPackageAddOnRadioCards
          name="kwasi"
          title="Professional Voice-Over"
          description="Enhance your video with a professional voice-over and script."
          image="" // ? what should we use here
          persona={persona}
          options={VOICE_OPTS}
          cost={KWASI}
          cols={2}
        />
      )}
      <TenantPackageAddOnCheckbox
        name="twilight"
        title="Twilight Upgrade"
        description="Add same-day twilight"
        image="" // ? what should we use here
        persona={persona}
        cost={TWILIGHT}
      />
    </>
  );
}
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { EnvelopeCount, EnvelopePaper, countSchema, paperSchema } from '~/tenants/bre/performable/print/envelopes/BreEnvelopesConfig';
import { BreEnvelopesRate } from '~/tenants/bre/performable/print/envelopes/BreEnvelopesData';
import BreEnvelopesJob from '~/tenants/bre/performable/print/envelopes/BreEnvelopesJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';

export default function BreEnvelopesForm({ context }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const paper = useZodFormFieldSingleValue<EnvelopePaper>('paper') ?? 'smooth_white';
  const count = useZodFormFieldSingleValue<EnvelopeCount>('count') ?? '250';

  const paperOptions = paperSchema.options.map<InputRadioCardOption>((value) => ({
    title: BreEnvelopesJob.paperType(value),
    description: 'paper',
    disabled: !BreEnvelopesRate[value][count],
    value,
  }))

  const countOptions = countSchema.options.map<InputRadioCardOption>((value) => {
    const rate = BreEnvelopesRate[paper][value];

    return {
      title: `${value} pieces`,
      cost: rate || 'N/A',
      disabled: !rate,
      value,
    };
  });

  return (
    <FormGroup>
      <FormHorizontal name="paper" label="Paper" required>
        <ZodFieldRadioCards name="paper" options={paperOptions} cols={2} />
      </FormHorizontal>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldRadioCards name="count" options={countOptions} cols={2} />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 7)}
        />
      </FormHorizontal>
      <BreDelivery
        name="delivery"
        label="Delivery"
        context={context}
        required
        free
      />
    </FormGroup>
  );
}

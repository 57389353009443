import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreWebsiteRate } from '~/tenants/bre/performable/website/BreWebsiteData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';

interface BreWebsiteDomianAddOnProps {
  persona: PersonaType;
  type: BreOrderType;
}

export default function BreWebsiteDomianAddOn({ persona, type }: BreWebsiteDomianAddOnProps) {
  const { DOMAIN } = BreWebsiteRate[type];

  return (
    <TenantPackageAddOnCheckbox
      name="domain"
      title="Custom Domain"
      description="Ship with your own custom domain"
      image="" // ? what image should we use here
      persona={persona}
      cost={DOMAIN}
    />
  );
}

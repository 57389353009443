import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderMetadata, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { DeliverableType } from '~common/model/Deliverable';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';

interface PhotosRevenueOptions {
  metadata: BreOrderMetadata;
  photos: number;
}

export default class BrePhotosJob extends BreJob<typeof BrePhotosConfig> {
  get performable() {
    return BrePhotosConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  static recommendedPhotos(metadata: BreOrderMetadata): number {
    if (metadata.type !== BreOrderType.MARKETING && metadata.sqft >= 1500) {
      const closest = Math.floor((metadata.sqft - 500) / 1000) * 1000 + 500;
      return closest / 100;
    }

    return 15;
  }

  static photosRevenue({ metadata, photos }: PhotosRevenueOptions): Big {
    const recommended = BrePhotosJob.recommendedPhotos(metadata);
    const base = metadata.type === BreOrderType.COMMERCIAL
      ? new Big(photos >= recommended ? '60' : '30').times(photos).add(100)
      : new Big(photos >= recommended ? '7' : '3.5').times(photos).add(95);

    return base.times(1.05);
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BrePhotosConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: BrePhotosJob.recommendedPhotos(this.order.metadata),
    };
  }

  // TODO: Missiing POIs times
  onsite(): number {
    const photos = this.metadata.photos + 25;
    const twilight = this.metadata.twilight ? 40 : 0;

    return photos + twilight;
  }

  revenueLines(): TenantOrderLine[] {
    const { COPYRIGHT, LOCAL_AREA, RUSH, TWILIGHT } = BrePhotosRate[this.order.metadata.type];
    const lines: TenantOrderLine[] = [];

    if (this.metadata.photos > 0) {
      const amount = BrePhotosJob.photosRevenue({
        metadata: this.order.metadata,
        photos: this.metadata.photos,
      });

      lines.push({
        id: 'photos',
        description: `${this.metadata.photos} Daytime Photos`,
        amount,
        discountable: true,
      });

      if (this.matchSocialPackage) {
        lines.push({
          id: 'photo_package',
          description: 'Social Media Package Photo Discount',
          amount: amount.times('0.1').neg(),
          discount: true,
        });
      }
    }

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(TWILIGHT),
      });
    }

    if (this.metadata.copyright) {
      const twilight = this.metadata.twilight ? 8 : 0;
      const count = this.metadata.photos + twilight;

      lines.push({
        id: 'copyright',
        description: `${count} Photos Copyright`,
        amount: new Big(COPYRIGHT).times(count),
      });
    }

    if (this.metadata.local_area && this.metadata.local_area.length > 0) {
      lines.push({
        id: 'poi',
        description: 'Points of Interest',
        amount: new Big(LOCAL_AREA).times(this.metadata.local_area.length),
      });
    }

    if (this.metadata.rush) {
      lines.push({
        id: 'rush_fee',
        description: 'Before noon rush fee',
        amount: new Big(RUSH),
      });
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const { LOCAL_AREA, TWILIGHT } = BrePhotosRate[this.order.metadata.type];
    const lines: TenantOrderLine[] = [];

    if (this.metadata.photos > 0) {
      const price = BrePhotosJob.photosRevenue({
        metadata: this.order.metadata,
        photos: this.metadata.photos,
      });

      lines.push({
        id: 'photos',
        description: `${this.metadata.photos} Daytime Photos`,
        amount: price
          .div('1.05')
          .times('0.65')
          .times(this.matchSocialPackage ? '0.9' : '1'),
      });
    }

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(TWILIGHT).div('1.05').times('0.65'),
      });
    }

    if (this.metadata.local_area && this.metadata.local_area.length > 0) {
      lines.push({
        id: 'poi',
        description: 'Points of Interest',
        amount: new Big(LOCAL_AREA).times(this.metadata.local_area.length).times('0.65'),
      });
    }

    return lines;
  }
}

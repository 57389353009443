import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreTwilightConfig from '~/tenants/bre/performable/twilight/BreTwilightConfig';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';

const BaseRates = {
  COPYRIGHT: '50',
  PHOTOS_8: '415',
  PHOTOS_15: '499',
  PHOTOS_25: '599',
};

export const BreTwilightRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    PHOTOS_8: '525',
  },
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BreTwilightJob extends BreJob<typeof BreTwilightConfig> {
  get performable() {
    return BreTwilightConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreTwilightConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: this.order.metadata.type === BreOrderType.COMMERCIAL ? 15 : 8,
      shoot_date: '' as TDateISODate,
    };
  }

  onsite(): number {
    if (this.metadata.photos <= 8) {
      return 40;
    }

    if (this.metadata.photos <= 15) {
      return 50;
    }

    return 60;
  }

  revenueLines(): TenantJobLine[] {
    const { COPYRIGHT, PHOTOS_8, PHOTOS_15, PHOTOS_25 } = BreTwilightRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.metadata.photos <= 8) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(PHOTOS_8),
      });
    } else if (this.metadata.photos <= 15) {
      lines.push({
        id: 'twilight',
        description: '15 Twilight Photos',
        amount: new Big(PHOTOS_15),
      });
    } else if (this.metadata.photos <= 25) {
      lines.push({
        id: 'twilight',
        description: '25 Twilight Photos',
        amount: new Big(PHOTOS_25),
      });
    }

    if (this.metadata.copyright) {
      lines.push({
        id: 'copyright',
        description: 'Release w/ copyright',
        amount: new Big(COPYRIGHT).times(this.metadata.photos),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];
    const split = '0.65';

    if (this.metadata.photos <= 8) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big('395').times(split),
      });
    } else if (this.metadata.photos <= 15) {
      lines.push({
        id: 'twilight',
        description: '15 Twilight Photos',
        amount: new Big('475').times(split),
      });
    } else if (this.metadata.photos <= 25) {
      lines.push({
        id: 'twilight',
        description: '25 Twilight Photos',
        amount: new Big('575').times(split),
      });
    }

    return lines;
  }
}

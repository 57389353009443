import { ReactNode } from 'react';
import Popup from '~/components/interactive/Popup';

export default function Tooltip({
  text,
  children,
  bottom = true,
}: {
  text: string;
  children: ReactNode;
  bottom?: boolean;
}) {
  return (
    <Popup activator={children} hover={0} className="inline-block cursor-default" strategy={bottom ? 'bottom' : 'best'}>
      <div
        className={`rounded-md bg-gray-800 bg-opacity-80 text-white text-xs tracking-widest font-semibold antialiased uppercase text-center px-2 py-0.5 ${
          bottom ? 'my-1' : '-mt-2'
        }`}
      >
        {text}
      </div>
    </Popup>
  );
}

import { TenantOrder } from '../../common/TenantOrder';
import { BRE_TRAVEL_FEES } from '../data';
import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BreFlyersJob from '~/tenants/bre/performable/print/flyers/BreFlyersJob';
import BreBrochuresJob from '~/tenants/bre/performable/print/brochures/BreBrochuresJob';
import BrePostcardsMailingJob from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingJob';
import BrePostcardsPrintOnlyJob from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyJob';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';
import BreCinematicJob from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';
import BreMatterportJob from '~/tenants/bre/performable/matterport/BreMatterportJob';

import { DateTime } from 'luxon';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreJob from '~/tenants/bre/model/BreJob';
import { Holiday } from '~common/holidays/holidays';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { datetime } from '~/lib/datettime';

// TODO:
/*Design fee for customization of an existing template must be an additional line item on the flyer job, not a separate "design only" job

Social media boosts should be their own job, not part of the virtual tour/single property website

Delivery addresses should be required for all print materials and delivery fee should be automatically added (this includes postcards with no mailing). The delivery fees will vary since the minimum fee is included in the cost of the flyers, but not included for the signs or postcards.

Sign riders - put a note on there, informing them that sign riders have a 3 business day turnaround time (and UPS adds an extra day)

Let agents know that content writing is available for a fee and, if they want to use this service, will need to be requested in advance to avoid delay in delivery*/

export class BreOrder extends TenantOrder<BreOrderContext, BreJob> {
  sendNotification(): {
    attachments: string[];
  } {
    const attachments: string[] = [];

    const copywriting = this.jobs.some((job) => {
      (job instanceof BreFlyersJob || job instanceof BreBrochuresJob) && job.metadata.content_writing;
    });

    if (copywriting) {
      // TODO
      attachments.push('TODO');
    }

    const brochure = this.jobs.some(
      (p) => p instanceof BreBrochuresJob || p instanceof BreFlyersJob || p instanceof BrePostcardsMailingJob || p instanceof BrePostcardsPrintOnlyJob,
    );

    if (brochure) {
      // TODO
      attachments.push('TODO');
    }

    const print = this.jobs.some((p) => p.isPrint());

    if (print) {
      // TODO
      attachments.push('TODO');
    }

    const video = this.jobs.some((p) => p instanceof BreCinematicJob || p instanceof BreAerialJob);

    if (video) {
      // TODO
      attachments.push('TODO');
    }

    const ready = this.jobs.some(
      (p) => p instanceof BrePhotosJob || p instanceof BreMatterportJob || p instanceof BreCinematicJob,
    );

    if (ready) {
      // TODO
      attachments.push('TODO');
    }

    return { attachments };
  }

  revenueLines(): TenantOrderLine[] {
    const { onsite, type } = this.context.metadata;
    const lines = super.revenueLines();

    const hasSameDayFlyers = this.jobs.some((job) => job instanceof  BreFlyersJob && job.needBy === 'same_day');
    const hasRushPhotos = this.jobs.some((job) => job instanceof BrePhotosJob && job.metadata.rush);

    if (hasSameDayFlyers && hasRushPhotos) {
      const { RUSH } = BrePhotosRate[type];

      lines.push({
        id: 'free_rush_photos',
        description: 'Same-day flyers photo rush discount',
        discount: true,
        amount: new Big(RUSH).neg(),
      });
    }

    if (onsite !== undefined && !onsite.excludeTravelFees) {
      this.context.appointments.forEach((appointment) => {
        const { zip } = appointment.address ?? onsite.address;
        const { fee } = BRE_TRAVEL_FEES.find(({ zipcodes }) => zipcodes.includes(zip)) ?? { };

        if (fee) {
          const date = datetime(appointment.start, this.location.timezone).toFormat('MM/dd');

          lines.push({
            id: `travel-${appointment.id}`,
            description: `Travel Fee (${date})`,
            amount: new Big(fee),
          });
        }
      });
    }

    // TODO: review weekend and holiday fees
    if (this.context.metadata.onsite) {
      if (
        this.context.appointments.some((a) => DateTime.fromISO(a.start).weekday >= 6) &&
        !this.context.metadata.onsite.excludeWeekendFees
      ) {
        lines.push({
          id: 'weekend',
          description: 'Weekend Fee',
          amount: new Big('100'),
        });
      }

      if (
        this.context.appointments.some(
          (a) =>
            a.holiday &&
            [
              Holiday.CHRISTMAS,
              Holiday.CHRISTMAS_EVE,
              Holiday.LABOR_DAY,
              Holiday.THANKSGIVING,
            ].includes(a.holiday),
        )
      ) {
        lines.push({
          id: 'holiday',
          description: 'Holiday Fee',
          amount: new Big('100'),
        });
      }
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const { onsite } = this.context.metadata;
    const lines = super.expenseLines();

    if (onsite !== undefined && !onsite.excludeTravelFees) {
      this.context.appointments.forEach((appointment) => {
        const { zip } = appointment.address ?? onsite.address;
        const { fee } = BRE_TRAVEL_FEES.find(({ zipcodes }) => zipcodes.includes(zip)) ?? { };

        if (fee) {
          const date = datetime(appointment.start, this.location.timezone).toFormat('MM/dd');

          lines.push({
            id: `travel-${appointment.id}`,
            description: `Travel Fee (${date})`,
            amount: new Big(fee),
          });
        }
      });
    }

    return lines;
  }
}

import dedent from 'dedent';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosFields from '~/tenants/bre/performable/photos/BrePhotosFields';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import BrePhotosLocalAreaAddOn from '~/tenants/bre/performable/photos/BrePhotosLocalAreaAddOn';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export const PHOTOS_OPTS = Array.from({ length: 11 })
  .map((_, i) => (i * 10) + 15)
  .map<InputSelectOption>((value) => ({
    label: `${value} Photos`,
    value: `${value}`,
  }));

export default function BrePhotosForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { COPYRIGHT, RUSH, TWILIGHT } = BrePhotosRate[context.metadata.type];

  return (
    <FormGroup>
      <BrePhotosFields metadata={context.metadata} />
      <FormHorizontal name="notes" label="Special Instructions">
        <ZodFieldTextarea name="notes" />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        {context.metadata.type === BreOrderType.COMMERCIAL && (
          <TenantPackageAddOnCheckbox
            name="copyright"
            title="Copyright"
            description="Release photos with copyrights"
            image="" // ? what image should we use here
            persona={persona}
            cost={COPYRIGHT} // TODO: Need to show $50/each
          />
        )}
        <TenantPackageAddOnCheckbox
          name="rush"
          title="Rush"
          description="Need your photos before noon?"
          image="" // ? what image should we use here
          persona={persona}
          cost={RUSH}
        />
        <TenantPackageAddOnCheckbox
          name="twilight"
          title="Same-day Twilight"
          description={dedent`
            Photos include exterior, interior, and twilight shots, all taken in \
            one session. For twilight-only photos, select the Twilight – Separate \
            Trip option, allowing a different shoot date if needed.
          `}
          image="" // ? what image should we use here
          persona={persona}
          cost={TWILIGHT}
        />
        <BrePhotosLocalAreaAddOn type={context.metadata.type} persona={persona} />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

/*
The process of taking multiple images, all at different exposures, allows photographers to capture a greater dynamic range and enhanced luminosity compared to standard photography.

By using blending techniques, we can deliver images with stunningly vivid details. These types of details are normally only able to be seen in real-life situations. Photographs that once appeared flat, lifeless and washed out will now appear spectacular and full of detail but not over-exaggerated.

All of our photos are edited by a professional team of editors manually blending using Photoshop. No auto programs or auto settings.  The windows in each image have clear views, fire in fireplaces and images placed on TV's.
 */

/*
Points of Interest/Community Photos

Would you like us to photograph locations near your property? Provide us the Name and Address and will we  add that to your Photography Package.
 */

/*
Same-Trip Twilight Upgrade  - TWILIGHT

ALL OUTSIDE AND INSIDE photos will be taken at the end of the day. We will start with the outside, then move to the inside and then take outside again at Twilight.
IF YOU WANT ONLY THE TWILIGHT TAKEN AT THE END OF THE DAY THEN CHOOSE OUR TWILIGHT - SEPARATE TRIP OPTION INSTEAD OF CLICKING THIS BOX!!!
You can even choose a different date for the Twilight separate shoot date if needed
 */

/*
ACK

Property to be photographed or filmed will be "Photo Ready" upon Photographers arrival, otherwise there will be a Same Day cancellation or delay fee.

Same-Day Cancellation = $125 (Less than 24 Hour Notice) or $175 (Less Than 3 Hour Notice).
Delay Fee = $50.00 per every 15 minutes up to 30 minutes

 */

/*
Twilight

Both interior and exterior twilight photos are taken during the same appointment. If you would like interior photos during the day, please add the standalone Twilight service instead.
 */

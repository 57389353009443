import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldAddress from '~/components/zod/ZodFieldAddress';
import { useTenant } from '~/tenants/common/TenantContextProvider';
import { TenantCustomerFormProps } from '~/tenants/common/form';

export default function BreCustomerFrom(props: TenantCustomerFormProps) {
  const tenant = useTenant();

  return (
    <FormGroup>
      <FormHorizontal name="office" label="Office Address">
        <ZodFieldAddress
          name="office"
          distance={tenant.deliveryOrigin}
          start={tenant.deliveryStart?.()}
          clearable
        />
      </FormHorizontal>
      <FormHorizontal name="home" label="Home Address">
        <ZodFieldAddress
          name="home"
          distance={tenant.deliveryOrigin}
          start={tenant.deliveryStart?.()}
          clearable
        />
      </FormHorizontal>
    </FormGroup>
  );
}
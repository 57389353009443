import Message from '~/components/Message';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { RiderShipping, shippingSchema, holesSchema } from '~/tenants/bre/performable/print/riders/BreRidersConfig';
import BreRidersJob from '~/tenants/bre/performable/print/riders/BreRidersJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';

const HOLES_OPTS = holesSchema.options.map<InputRadioCardOption>((value) => ({
  title: `${value} holes`,
  description: 'rider',
  value,
}));

const SHIPPING_OPTS = shippingSchema.options.map<InputRadioCardOption>((value) => ({
  title: BreRidersJob.shippingType(value),
  description: 'delivered',
  cost: BreRidersJob.shippingFee(value),
  value,
}));

export default function BreRidersForm({ context }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const shipping = useZodFormFieldSingleValue<RiderShipping>('shipping');

  const hasSameDayFlyers = BreRidersJob.hasSameDayFlyers(context);

  return (
    <FormGroup>
      <FormHorizontal name="holes" label="Holes" required>
        <ZodFieldRadioCards name="holes" options={HOLES_OPTS} cols={2} />
      </FormHorizontal>
      {!hasSameDayFlyers && (
        <FormHorizontal name="shipping" label="Shipping" required>
          <ZodFieldRadioCards name="shipping" options={SHIPPING_OPTS} cols={2} />
        </FormHorizontal>
      )}
      <FormHorizontal name={'need_by'} label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => {
            const days = shipping === 'courier' ? 3 : 4;
            return isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), days);
          }}
        />
      </FormHorizontal>
      {!hasSameDayFlyers && shipping === 'courier' && (
        <BreDelivery
          name="delivery"
          label="Delivery"
          context={context}
          required
        />
      )}

      {hasSameDayFlyers && (
        <Message type="info">
          Enjoy <b>free delivery</b> with your same-day printed flyers!
        </Message>
      )}
    </FormGroup>
  );
}

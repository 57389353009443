export default function IconDownload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 10"
      id="Download-Button-2--Streamline-Micro"
      width="100%"
      height="100%"
    >
      <desc>{'Download Button 2 Streamline Icon: https://streamlinehq.com'}</desc>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5 0.5v6" strokeWidth={1} />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m7 4.5 -2 2 -2 -2" strokeWidth={1} />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.5 7.5v1a1 1 0 0 0 1 1h7a1 1 0 0 0 1 -1v-1"
        strokeWidth={1}
      />
    </svg>
  );
}

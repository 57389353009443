import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { TWT_REEL_RAW, TWT_REEL_VOICEOVER } from '~/tenants/twt/performable/reel/TwtReelJob';
import Sections from '~/components/layout/Sections';

export default function TwtReelForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <Sections>
      <TenantPackageAddOnCheckbox
        name="voiceover"
        persona={persona}
        image="tenant/twt/cameo/thumbnail.jpeg"
        title="Agent Cameo / Voiceover"
        cost={TWT_REEL_VOICEOVER}
        description="Add a personal touch to your video with an on screen cameo or a descriptive voiceover."
      />
      <TenantPackageAddOnCheckbox
        name="raw"
        persona={persona}
        image="tenant/twt/reel/thumbnail.png"
        title="Raw Footage"
        cost={TWT_REEL_RAW}
        description="We will deliver the unedited video files used to build the reels."
      />
    </Sections>
  );
}

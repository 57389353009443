import DropButton from '~/components/interactive/DropButton';
import InputDate, { InputDateProps } from '~/components/input/InputDate';
import { useTz } from '~/components/hooks/useTz';
import IconCalendar from '~/components/icons/streamline/line/IconCalendar';

interface InputDayProps extends Omit<Extract<InputDateProps, { required: true }>, 'required'> {
  placeholder?: string;
}

export default function InputDay({ placeholder = 'Select Day', ...props }: InputDayProps) {
  const tz = useTz();

  return (
    <DropButton icon={<IconCalendar />} text={props.value ? tz(props.value).toFormat('MM-dd-yyyy') : placeholder}>
      <div className="p-2">
        <InputDate required {...props} />
      </div>
    </DropButton>
  );
}

import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';
import { deliverySchema } from '~/tenants/bre/performable/common';

export type SignType = z.infer<typeof typeSchema>;

export type SignShipping = z.infer<typeof shippingSchema>;

export const typeSchema = z.enum([
  'sign_24x24',
  'sign_24x18',
  'aframe_24x24',
]);

export const shippingSchema = z.enum(['courier', 'ups']);

export default createPerformableConfig(
  'signs',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: typeSchema,
      shipping: shippingSchema,
      need_by: zodTDateISODate(),
      delivery: deliverySchema.optional(),
    }).superRefine(({ delivery, shipping }, ctx) => {
      if (shipping === 'courier' && delivery === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'delivery is required when shipping is by courier',
          path: ['delivery'],
        });
      }
    }),
  },
  {
    name: 'Agent Yard/A-Frame Signs',
    group: 'Agent Marketing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: 'For Sale signs and Open House A-Frames.',
  },
);

import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { BusinessCardCount, BusinessCardPaper, countSchema, paperSchema } from '~/tenants/bre/performable/print/cards/BreBusinessCardsConfig';
import { BreBusinessCardsRate } from '~/tenants/bre/performable/print/cards/BreBusinessCardsData';
import BreBusinessCardsJob from '~/tenants/bre/performable/print/cards/BreBusinessCardsJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';

export default function BreBusinessCardsForm({ context }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const count = useZodFormFieldSingleValue<BusinessCardCount>('count') ?? '50';
  const paper = useZodFormFieldSingleValue<BusinessCardPaper>('paper') ?? 'uv_glossy';


  const paperOptions = paperSchema.options.map<InputRadioCardOption>((value) => ({
    title: BreBusinessCardsJob.paperType(value),
    description: '16 pt. Cover stock',
    disabled: !BreBusinessCardsRate[value][count],
    value,
  }));

  const countOptions = countSchema.options.map<InputRadioCardOption>((value) => {
    const rate = BreBusinessCardsRate[paper][value];

    return {
      title: `${value} cards`,
      cost: rate,
      disabled: !rate,
      value,
    };
  })

  return (
    <FormGroup>
      <FormHorizontal name="paper" label="Paper" required>
        <ZodFieldRadioCards name="paper" options={paperOptions} cols={2} />
      </FormHorizontal>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldRadioCards name="count" options={countOptions} />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 7)}
        />
      </FormHorizontal>
      <BreDelivery
        name="delivery"
        label="Delivery"
        context={context}
        required
        free
      />
    </FormGroup>
  );
}

import Sections from '~/components/layout/Sections';
import PerformableGroupForm, { usePerformableGroup } from '~/components/performable/PerformableGroupForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BrePackageGroup from '~/tenants/bre/package/BrePackageGroup';
import {
  BrePackageBoostInclude,
  BrePackageDuskInclude,
  BrePackageFloorplanInclude,
  BrePackageMatterportUpgrade,
  BrePackagePhotosInclude,
  BrePackageSocialInclude,
  BrePackageWebsiteInclude,
} from '~/tenants/bre/package/common';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePhotosFields from '~/tenants/bre/performable/photos/BrePhotosFields';
import BrePhotosLocalAreaAddOn from '~/tenants/bre/performable/photos/BrePhotosLocalAreaAddOn';
import BreSocialAddOns from '~/tenants/bre/performable/social/BreSocialAddOns';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreWebsiteDomianAddOn from '~/tenants/bre/performable/website/BreWebsiteDomianAddOn';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageIncludes } from '~/tenants/common/package';

export default function BreSocialPackageForm() {
  const { order } = usePerformableGroup<BreOrderContext>();

  return (
    <Sections>
      <TenantPackageIncludes>
        <PerformableGroupForm performable={BrePhotosConfig}>
          <BrePackagePhotosInclude />
        </PerformableGroupForm>
        <BrePackageFloorplanInclude />
        <BrePackageSocialInclude />
        <BrePackageBoostInclude />
        <BrePackageWebsiteInclude />
        <BrePackageDuskInclude />
      </TenantPackageIncludes>

      <BrePackageGroup
        subtitle="Customize the ground photography."
        performable={BrePhotosConfig}
      >
        <BrePhotosFields metadata={order.metadata} />
        <BrePhotosLocalAreaAddOn type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="Earth-Tone Color 2D Floorplan."
        performable={BreFloorplanConfig}
      >
        <BrePackageMatterportUpgrade />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="Engages buyers and attracts future clients."
        performable={BreSocialConfig}
      >
        <BreSocialAddOns type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="Market your listing in the city of the property."
        performable={BreBoostConfig}
      >
        <BreBoostFields />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="1-year subscription to a single property website page"
        performable={BreWebsiteConfig}
      >
        <ZodFieldHidden name="type" value="website" />
        <BreWebsiteDomianAddOn type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>
    </Sections>
  );
}

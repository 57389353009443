import { useState } from 'react';
import { match } from 'ts-pattern';
import { format_money } from '~/components/Money';
import { FormHorizontal } from '~/components/form/layout';
import InputRadioCards, { InputRadioCardOption } from '~/components/input/InputRadioCards';
import ZodFieldAddress from '~/components/zod/ZodFieldAddress';
import { useZodFormFieldObject } from '~/components/zod/ZodForm';
import { DistanceAddress } from '~/lib/model';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { deliveryFee } from '~/tenants/bre/performable/common';
import { useTenant } from '~/tenants/common/TenantContextProvider';

enum Address {
  CUSTOM = 'custom',
  HOME = 'home',
  OFFICE = 'office',
  PROPERTY = 'property',
}

interface BreDeliveryProps {
  name: string;
  label: string;
  context: BreOrderContext;
  required?: boolean;
  free?: boolean;
}

export default function BreDelivery({ name, label, context, required, free }: BreDeliveryProps) {
  const { home, office } = context.buyer;

  const tenant = useTenant();
  const [prefixed, delivery, setDelivery] = useZodFormFieldObject<DistanceAddress>(name);

  const [address, setAddress] = useState(addressFor(delivery, context));

  const deliveryOpts = deliveryOptionsFor(context, free);
  const fee = !free && delivery?.time
    ? deliveryFee(delivery.time, 'rate')
    : undefined;
  const error = !free && delivery?.time && !fee
    ? 'The selected address is out of range'
    : undefined;
  const description = !free && address === Address.CUSTOM && fee
    ? `This address has a delivery fee of ${format_money(fee)}.`
    : undefined;

  return (
    <FormHorizontal
      name={name}
      label={label}
      error={error}
      description={description}
      required={required}
    >
      <div className="flex flex-col gap-2">
        {(office?.line1 || home?.line1) && (
          <>
            <InputRadioCards
              options={deliveryOpts}
              cols={deliveryOpts.length % 2 ? deliveryOpts.length : 2}
              onChange={(value) => {
                setAddress(
                  match(value)
                    .with(Address.CUSTOM, () => Address.CUSTOM)
                    .with(Address.HOME, () => Address.HOME)
                    .with(Address.OFFICE, () => Address.OFFICE)
                    .with(Address.PROPERTY, () => Address.PROPERTY)
                    .otherwise(() => Address.CUSTOM)
                );
                setDelivery(
                  match(value)
                    .with(Address.HOME, () => home)
                    .with(Address.OFFICE, () => office)
                    .with(Address.PROPERTY, () => context.address)
                    .otherwise(() => null)
                );
              }}
              value={address}
            />

            {delivery && address !== Address.CUSTOM && (
              <>
                <input type="hidden" name={`${prefixed}.lat`} value={delivery.lat} />
                <input type="hidden" name={`${prefixed}.long`} value={delivery.long} />
                <input type="hidden" name={`${prefixed}.line1`} value={delivery.line1} />
                <input type="hidden" name={`${prefixed}.line2`} value={delivery.line2 ?? ''} />
                <input type="hidden" name={`${prefixed}.state`} value={delivery.state} />
                <input type="hidden" name={`${prefixed}.city`} value={delivery.city} />
                <input type="hidden" name={`${prefixed}.zip`} value={delivery.zip} />
                <input type="hidden" name={`${prefixed}.distance`} value={delivery.distance ?? '0'} />
                <input type="hidden" name={`${prefixed}.time`} value={delivery.time ?? '0'} />
              </>
            )}
          </>
        )}

        {address === Address.CUSTOM && (
          <ZodFieldAddress
            name="delivery"
            distance={tenant.deliveryOrigin}
            start={tenant.deliveryStart?.()}
            clearable
          />
        )}
      </div>
    </FormHorizontal>
  );
}

function addressFor(delivery: DistanceAddress | undefined, { address, buyer }: BreOrderContext): Address {
  if (delivery !== undefined) {
    const { home, office } = buyer;

    if (home?.time && delivery.line1 === home.line1) {
      return Address.HOME;
    }

    if (office?.time && delivery.line1 === office.line1) {
      return Address.OFFICE;
    }

    if (address?.time && delivery.line1 === address.line1) {
      return Address.PROPERTY;
    }
  }

  return Address.CUSTOM;
}

function deliveryOptionsFor({ address, buyer }: BreOrderContext, free: boolean | undefined): InputRadioCardOption[] {
  const { home, office } = buyer;
  const options: InputRadioCardOption[] = [{
    title: 'Custom',
    description: !free
      ? 'choose an address to see the delivery fee'
      : 'search for a delivery address',
    value: Address.CUSTOM,
  }];

  if (address?.time) {
    options.unshift({
      title: 'Property',
      description: address.line1,
      cost: !free ? deliveryFee(address.time, 'rate') : undefined,
      value: Address.PROPERTY,
    });
  }

  if (home?.time) {
    options.unshift({
      title: 'Home',
      description: home.line1,
      cost: !free ? deliveryFee(home.time, 'rate') : undefined,
      value: Address.HOME,
    });
  }

  if (office?.time) {
    options.unshift({
      title: 'Office',
      description: office.line1,
      cost: !free ? deliveryFee(office.time, 'rate') : undefined,
      value: Address.OFFICE,
    });
  }

  return options;
}

import { LetterheadCount, LetterheadPaper } from '~/tenants/bre/performable/print/letterheads/BreLetterheadsConfig';

type CountMap = Record<LetterheadCount, string>;

type PaperMap = Record<LetterheadPaper, Partial<CountMap>>;

export const BreLetterheadsRate: PaperMap = {
  smooth_white: {
    250: '175',
    500: '225',
    1000: '295',
    2500: '475',
  },
  premium_linen_white: {
    250: '240',
    500: '295',
    1000: '325',
    2500: '515',
  },
};

export const BreLetterheadsPay: PaperMap = {
  smooth_white: {
    250: '150',
    500: '195',
    1000: '255',
    2500: '410',
  },
  premium_linen_white: {
    250: '$205',
    500: '$255',
    1000: '$280',
    2500: '$445',
  },
};

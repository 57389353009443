import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import dedent from 'dedent';

export default createPerformableConfig(
  'portraits',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.enum(['studio', 'onsite']),
      extra_edit: coerceBoolean().optional(),
      extra_pose: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Professional Agent Portraits',
    group: 'Agent Marketing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: dedent`
      We specialize in high-quality headshots and lifestyle photos, showcasing \
      real estate agents and capturing their essence.
    `,
  },
);

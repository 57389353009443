import BreJob from '~/tenants/bre/model/BreJob';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { DeliverableType } from '~common/model/Deliverable';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { match } from 'ts-pattern';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicData';

export default class BreCinematicJob extends BreJob<typeof BreCinematicConfig> {
  get performable() {
    return BreCinematicConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get submission(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.VIDEO;
  }

  get editing() {
    return !this.metadata.self_edited;
  }

  get videoRevenue(): Big {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const isCommercial = this.order.metadata.type === BreOrderType.COMMERCIAL;

      if (this.metadata.aerial_upgrade) {
        return isCommercial && this.metadata.duration
          ? new Big('656').times(this.metadata.duration).add(-198)
          : new Big('0.22').times(this.order.metadata.sqft).add(456)
      }

      return isCommercial && this.metadata.duration
        ? new Big('656').times(this.metadata.duration).add(-565)
        : new Big('0.21').times(this.order.metadata.sqft).add(209)
    }

    return new Big('0');
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.VIDEO,
    };
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreCinematicConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite(): number {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const video = match(this.order.metadata.sqft)
        .when((sqft) => sqft < 1500, () => 60)
        .when((sqft) => sqft < 2500, () => 75)
        .when((sqft) => sqft < 3000, () => 85)
        .when((sqft) => sqft < 5000, () => 90)
        .when((sqft) => sqft < 6500, () => 120)
        .when((sqft) => sqft < 8000, () => 150)
        .otherwise(() => 180);
      const aerial = this.metadata.aerial_upgrade || this.metadata.aerial_photos ? 30 : 0;

      return video + aerial;
    }

    return 0;
  }

  revenueLines(): TenantJobLine[] {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const {
        AERIAL_PHOTOS,
        INTRO,
        KWASI,
        NARRATION,
        RUSH,
        TWILIGHT,
      } = BreCinematicRate[this.order.metadata.type];
      const lines: TenantJobLine[] = [];

      if (this.order.metadata.sqft > 0) {
        lines.push({
          id: 'video',
          description: `Cinematic Film${this.metadata.aerial_upgrade ? ' (with aerial)' : ''}`,
          amount: this.videoRevenue,
        });
      }

      if (this.metadata.intro) {
        lines.push({
          id: 'intro',
          description: 'Agent Intro',
          amount: new Big(INTRO),
        });
      }
  
      if (this.metadata.narration) {
        lines.push({
          id: 'narration',
          description: 'Agent Narration',
          amount: new Big(NARRATION),
        });

        if (this.metadata.kwasi) {
          lines.push({
            id: 'kwasi',
            description: `Professional Voice-Over ${this.metadata.kwasi}`,
            amount: new Big(KWASI),
          });
        }
      }
  
      if (this.metadata.twilight) {
        lines.push({
          id: 'twilight',
          description: 'Twilight Transitions',
          amount: new Big(TWILIGHT),
        });
      }
  
      if (this.metadata.aerial_photos) {
        lines.push({
          id: 'aerial_photos',
          description: '5 Aerial Photos',
          amount: new Big(AERIAL_PHOTOS),
        });
      }

      if (this.metadata.rush) {
        lines.push({
          id: 'rush_fee',
          description: 'Next-day rush fee',
          amount: new Big(RUSH),
        });
      }
  
      return lines;
    }

    return [];
  }

  expenseLines(): TenantJobLine[] {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const lines: TenantJobLine[] = [];

      if (this.order.metadata.sqft > 0) {
        lines.push({
          id: 'video',
          description: `Cinematic Film${this.metadata.aerial_upgrade ? ' (with aerial)' : ''}`,
          amount: this.videoRevenue.div('1.05').times(this.metadata.self_edited ? '0.5' : '0.7'),
        });
      }

      if (this.metadata.intro) {
        lines.push({
          id: 'intro',
          description: 'Agent Intro',
          amount: new Big('88'),
        });
      }

      if (this.metadata.narration) {
        lines.push({
          id: 'narration',
          description: 'Agent Narration',
          amount: new Big('125'),
        });

        if (this.metadata.kwasi) {
          lines.push({
            id: 'kwasi',
            description: `Professional Voice-Over (${this.metadata.kwasi})`,
            amount: new Big('125'),
          })
        }
      }

      if (this.metadata.twilight) {
        lines.push({
          id: 'twilight',
          description: 'Twilight Transitions',
          amount: new Big('200'),
        });
      }
  
      if (this.metadata.aerial_photos) {
        lines.push({
          id: 'aerial_photos',
          description: '5 Aerial Photos',
          amount: new Big('65'),
        });
      }
  
      return lines;
    }

    return [];
  }
}

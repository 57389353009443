import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreStagingConfig from '~/tenants/bre/performable/staging/BreStagingConfig';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';

const BaseRates = {
  TIER_1: '50',
  TIER_2: '45',
  TIER_3: '40',
  TIER_4: '35',
  ITEM_REMOVAL: '25',
  SPACE_RENOVATION: '35',
};

export const BreStagingRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BreStagingJob extends BreJob<typeof BreStagingConfig> {
  get performable() {
    return BreStagingConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreStagingConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      style: 'modern',
      quantity: 1,
    };
  }

  // TODO: Waiting onsite times from Chris
  onsite(): number {
    return 0;
  }

  revenueLines(): TenantJobLine[] {
    const {
      TIER_1,
      TIER_2,
      TIER_3,
      TIER_4,
      ITEM_REMOVAL,
      SPACE_RENOVATION,
    } = BreStagingRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];
    const tier = match(this.metadata.quantity)
      .when((q) => q <= 3, () => TIER_1)
      .when((q) => q <= 6, () => TIER_2)
      .when((q) => q <= 9, () => TIER_3)
      .otherwise(() => TIER_4)

    lines.push({
      id: 'virtual_staging',
      description: `x${this.metadata.quantity} Virtual Staging`,
      amount: new Big(tier).times(this.metadata.quantity),
    });

    if (this.metadata.item_removal) {
      lines.push({
        id: 'item_removal',
        description: `x${this.metadata.item_removal} Items Removal`,
        amount: new Big(ITEM_REMOVAL).times(this.metadata.item_removal),
      });
    }

    if (this.metadata.space_renovation) {
      lines.push({
        id: 'space_renovation',
        description: `x${this.metadata.space_renovation} Space Renovations`,
        amount: new Big(SPACE_RENOVATION).times(this.metadata.space_renovation),
      });
    }

    return lines;
  }
}

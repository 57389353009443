import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { deliveryFee, toNeedBy } from '~/tenants/bre/performable/common';
import BreFlyersConfig from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';
import BreRidersConfig, { RiderShipping } from '~/tenants/bre/performable/print/riders/BreRidersConfig';
import { BreRidersPay, BreRidersRate } from '~/tenants/bre/performable/print/riders/BreRidersData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BreRidersJob extends BreJob<typeof BreRidersConfig> {
  get performable() {
    return BreRidersConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get shippingType(): string {
    const { shipping } = this.metadata;
    return BreRidersJob.shippingType(shipping);
  }

  get shippingFee(): string {
    const { shipping } = this.metadata;
    return BreRidersJob.shippingFee(shipping);
  }

  get hasSameDayFlyers(): boolean {
    return BreRidersJob.hasSameDayFlyers(this.order);
  }

  static shippingType(shipping: RiderShipping): string {
    return match(shipping)
      .with('courier', () => '2-Days Courier')
      .with('ups', () => '3-Days UPS')
      .exhaustive();
  }

  static shippingFee(shipping: RiderShipping): string {
    const { COURIER, UPS } = BreRidersRate;

    return match(shipping)
      .with('courier', () => COURIER)
      .with('ups', () => UPS)
      .exhaustive();
  }

  static hasSameDayFlyers(order: BreOrderContext): boolean {
    return order.jobs.some(({ metadata, performable_id }) => {
      return performable_id === BreFlyersConfig.id
        && toNeedBy(metadata.need_by) === 'same_day';
    });
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreRidersConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      shipping: 'courier',
      holes: '2',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { RIDER } = BreRidersRate;
    const { delivery, holes, shipping } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (RIDER) {
      lines.push({
        id: 'riders_print',
        description: `24'' x 6'' Yard Sign Rider (${holes} holes)`,
        amount: new Big(RIDER),
      });
    }

    if (!this.hasSameDayFlyers) {
      if (this.shippingFee) {
        lines.push({
          id: 'shipping_fee',
          description: `${this.shippingType} shipping`,
          amount: new Big(this.shippingFee),
        });
      }
  
      if (shipping === 'courier' && delivery?.time) {
        const rate = deliveryFee(delivery.time, 'rate');
  
        if (rate) {
          lines.push({
            id: 'delivery_fee',
            description: `Delivery Fee (${delivery.city})`,
            amount: new Big(rate)
          });
        }
      }
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { COURIER } = BreRidersPay;
    const { delivery, holes, shipping } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (COURIER) {
      lines.push({
        id: 'riders_print',
        description: `24'' x 6'' Yard Sign Rider (${holes} holes)`,
        amount: new Big(COURIER),
      });
    }

    if (!this.hasSameDayFlyers && shipping === 'courier' && delivery?.time) {
      const pay = deliveryFee(delivery.time, 'pay');

      if (pay) {
        lines.push({
          id: 'delivery_fee',
          description: `Delivery Fee (${delivery.city})`,
          amount: new Big(pay)
        });
      }
    }

    return lines;
  }
}

import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnRepeat } from '~/tenants/common/package';

interface BrePhotosLocalAreaAddOnProps {
  persona: PersonaType;
  type: BreOrderType;
}

export default function BrePhotosLocalAreaAddOn({ persona, type }: BrePhotosLocalAreaAddOnProps) {
  const { LOCAL_AREA } = BrePhotosRate[type];

  return (
    <TenantPackageAddOnRepeat
      name="local_area"
      title="Local Area Photos"
      description="Add-On local area photos if you can't locate an image from our gallery"
      image="" // ? what image should we use here
      persona={persona}
      cost={LOCAL_AREA}
    >
      <div className="flex gap-4">
        <ZodFieldInput name="name" placeholder="Name" />
        <ZodFieldInput name="location" placeholder="Directions" />
      </div>
    </TenantPackageAddOnRepeat>
  );
}

import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

const BaseRate = {
  BASELINE: '42',
  RUSH: '50',
  STANDALONE: '50',
  SQFT: '0.021',
};

export const BreFloorplanRate: Record<BreOrderType, typeof BaseRate> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRate,
    SQFT: '0.03',
  },
  [BreOrderType.MARKETING]: BaseRate,
  [BreOrderType.RENTAL]: BaseRate,
  [BreOrderType.RESIDENTIAL]: BaseRate,
};

const BasePay = {
  BASELINE: '5',
  STANDALONE: '50',
  SQFT: '0.01',
};

export const BreFloorplanPay: Record<BreOrderType, typeof BasePay> = {
  [BreOrderType.COMMERCIAL]: {
    ...BasePay,
    BASELINE: '-14',
    SQFT: '0.02',
  },
  [BreOrderType.MARKETING]: BasePay,
  [BreOrderType.RENTAL]: BasePay,
  [BreOrderType.RESIDENTIAL]: BasePay,
};

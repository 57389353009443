import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { GeocodedAddressSchema } from '~/lib/model';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreBookletsConfig from '~/tenants/bre/performable/print/booklets/BreBookletsConfig';
import BreBrochuresConfig from '~/tenants/bre/performable/print/brochures/BreBrochuresConfig';
import BreBusinessCardsConfig from '~/tenants/bre/performable/print/cards/BreBusinessCardsConfig';
import BreEnvelopesConfig from '~/tenants/bre/performable/print/envelopes/BreEnvelopesConfig';
import BreFlyersConfig from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';
import BreLetterheadConfig from '~/tenants/bre/performable/print/letterheads/BreLetterheadsConfig';
import BrePostcardsPrintOnlyConfig from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyConfig';
import BrePostcardsMailingConfig from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingConfig';
import BreRidersConfig from '~/tenants/bre/performable/print/riders/BreRidersConfig';
import BreSignsConfig from '~/tenants/bre/performable/print/signs/BreSignsConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';
import BreStagingConfig from '~/tenants/bre/performable/staging/BreStagingConfig';
import BreTwilightConfig from '~/tenants/bre/performable/twilight/BreTwilightConfig';
import BreSlideshowConfig from '~/tenants/bre/performable/slideshow/BreSlideshowConfig';
import BreDesignConfig from '~/tenants/bre/performable/print/design/BreDesignConfig';
import BreFoldersConfig from '~/tenants/bre/performable/print/folders/BreFoldersConfig';
import BreCoversConfig from '~/tenants/bre/performable/print/covers/BreCoversConfig';
import BreGuidesConfig from '~/tenants/bre/performable/print/guides/BreGuidesConfig';
import BreCustomConfig from '~/tenants/bre/performable/custom/BreCustomConfig';
import { BreCustomerMetadata } from '~/tenants/bre/model/BreCustomer';

export enum BreOrderType {
  COMMERCIAL = 'commercial',
  MARKETING = 'marketing',
  RENTAL = 'rental',
  RESIDENTIAL = 'residential',
}

export enum BreOrderAccess {
  AGENT = 'agent',
  SELLER = 'seller',
  COMBO = 'combo',
}

export const BreOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      onsite: z
        .object({
          address: GeocodedAddressSchema,
          gateCode: z.string().optional(),
          comboCode: z.string().optional(),
          comboLocation: z.string().optional(),
          excludeWeekendFees: z.boolean().optional(),
          excludeTravelFees: z.boolean().optional(),
          accessToProperty: z.enum([BreOrderAccess.AGENT, BreOrderAccess.COMBO, BreOrderAccess.SELLER]).optional(),
        })
        .optional(),
      comments: z.string().optional(),
    })
    .and(
      z.discriminatedUnion('type', [
        z.object({
          type: z.literal(BreOrderType.RESIDENTIAL),
          sqft: z.coerce.number(),
          beds: z.coerce.number().min(1),
          baths: z.coerce.number().min(1),
          built: z.coerce.number().optional(),
          colistNumber: z.string().optional(),
          colistEmail: z.string().optional(),
          colistPhone: z.string().optional(),
        }),
        z.object({
          type: z.literal(BreOrderType.COMMERCIAL),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(BreOrderType.RENTAL),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(BreOrderType.MARKETING),
        }),
      ]),
    ),
};
export type BreOrderMetadata = ZodVersionedMetadata<typeof BreOrderSchema>;
export type BrePerformableConfig =
  | typeof BreMatterportConfig
  | typeof BreAerialConfig
  | typeof BreCinematicConfig
  | typeof BreFloorplanConfig
  | typeof BrePhotosConfig
  | typeof BreBookletsConfig
  | typeof BreBrochuresConfig
  | typeof BreBusinessCardsConfig
  | typeof BreEnvelopesConfig
  | typeof BreFlyersConfig
  | typeof BreLetterheadConfig
  | typeof BrePostcardsPrintOnlyConfig
  | typeof BrePostcardsMailingConfig
  | typeof BreRidersConfig
  | typeof BreSignsConfig
  | typeof BrePortraitsConfig
  | typeof BreSocialConfig
  | typeof BreWebsiteConfig
  | typeof BreBoostConfig
  | typeof BreDuskConfig
  | typeof BreStagingConfig
  | typeof BreTwilightConfig
  | typeof BreSlideshowConfig
  | typeof BreDesignConfig
  | typeof BreFoldersConfig
  | typeof BreCoversConfig
  | typeof BreGuidesConfig
  | typeof BreCustomConfig;

export type BreOrderContext = TenantOrderContext<
  BreOrderMetadata,
  BrePerformableConfig,
  BreCustomerMetadata
>;

import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { PersonaType } from '~/tenants/common/TenantPersona';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';

export const TWT_REEL_VOICEOVER = 100;
export const TWT_REEL_RAW = 75;

export class TwtReelJob extends TwtJob<typeof TwtReelConfig> {
  get performable() {
    return TwtReelConfig;
  }

  get eligible(): boolean {
    return (
      this.order.metadata.type === TwtOrderType.FOR_SALE_LISTING && this.order.metadata.subtype !== TwtPropertyType.LAND
    );
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtReelConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtReelConfig.schema),
    } as any;
  }

  get short() {
    return 'REEL';
  }

  get deliverable(): DeliverableType {
    return DeliverableType.SOCIAL;
  }

  onsite(): number {
    return this.provider?.config.reel_onsite ?? 30;
  }

  get submission() {
    return true;
  }

  get configurable() {
    return true;
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.VIDEO };
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.voiceover) {
      info.push({
        name: 'Voiceover',
        value: 'Yes',
        provider: true,
        schedule: true,
      });
    }

    return info;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      amount: new Big(250),
      description: '45-60 Second Video',
      id: 'reel',
    });

    if (this.metadata.voiceover) {
      lines.push({
        amount: new Big(80),
        description: 'Agent Cameo / Voiceover',
        id: 'voiceover',
      });
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      amount: new Big(295),
      description: this.metadata.voiceover ? '45-60 Second Video' : this.performable.name,
      discountable: true,
      id: 'reel',
      taxable: true,
    });

    if (this.metadata.voiceover) {
      lines.push({
        amount: new Big(100),
        description: 'Agent Cameo / Voiceover',
        discountable: true,
        taxable: true,
        id: 'voiceover',
      });
    }

    if (this.metadata.raw) {
      lines.push({
        amount: new Big(TWT_REEL_RAW),
        description: 'Raw Social Footage',
        discountable: true,
        taxable: true,
        id: 'raw',
      });
    }

    return lines;
  }
}

import InputCheckbox, { InputCheckBoxProps, InputCheckboxWrapper } from '~/components/input/InputCheckbox';
import { useValibotFieldSingle } from '~/components/valibot/ValibotForm';

export default function ValibotFieldCheckbox({
  name,
  value,
  force = true,
  ...props
}: Omit<InputCheckBoxProps, 'checked' | 'onChange'> & {
  name: string;
  value?: string;
  force?: boolean;
  label?: string;
}) {
  const [prefixed, formValue, setValue] = useValibotFieldSingle(name);

  const checkedValue = value ?? 'true';
  const uncheckedValue = value ? '' : 'false';
  const checked = formValue === checkedValue;

  return (
    <InputCheckboxWrapper label={props.label || ''}>
      <InputCheckbox
        {...props}
        name={checked || force ? prefixed : undefined}
        checked={checked}
        value={checked ? checkedValue : uncheckedValue}
        onChange={(on) => {
          setValue(on ? checkedValue : uncheckedValue);
        }}
      />
    </InputCheckboxWrapper>
  );
}

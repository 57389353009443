export default function IconSolidAerial() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10" id="Drone--Streamline-Micro">
      <desc>{'Drone Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 1.125C0 0.78 0.28 0.5 0.625 0.5h2.667a0.625 0.625 0 1 1 0 1.25h-0.709v0.67h4.834v-0.67h-0.708a0.625 0.625 0 1 1 0 -1.25h2.666a0.625 0.625 0 0 1 0 1.25h-0.708v0.67H9.5a0.5 0.5 0 0 1 0.5 0.5v0.945A1.885 1.885 0 0 1 8.115 5.75h-0.083c0.543 0.773 0.84 1.7 0.84 2.657v0.343a0.625 0.625 0 0 1 -1.25 0v-0.343c0 -0.511 -0.117 -1.01 -0.334 -1.463a2.58 2.58 0 0 1 -1.942 0.883h-0.692a2.577 2.577 0 0 1 -1.944 -0.886 3.374 3.374 0 0 0 -0.335 1.466v0.343a0.625 0.625 0 0 1 -1.25 0v-0.343c0 -0.957 0.297 -1.884 0.84 -2.657h-0.08A1.885 1.885 0 0 1 0 3.865V2.92a0.5 0.5 0 0 1 0.5 -0.5h0.833v-0.67H0.625A0.625 0.625 0 0 1 0 1.125ZM5 6a1 1 0 1 0 0 -2 1 1 0 0 0 0 2Z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
  );
}

export default function IconSolidFloorplan() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10" id="Ruler--Streamline-Micro">
      <desc>{'Ruler Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0.147 7.084a0.503 0.503 0 0 1 0 -0.71l1.037 -1.037L2.58 6.733a0.5 0.5 0 0 0 0.707 -0.707L1.89 4.63l1.014 -1.014L4.3 5.012a0.5 0.5 0 0 0 0.707 -0.707L3.612 2.909l1.013 -1.013L6.02 3.292a0.5 0.5 0 0 0 0.707 -0.707L5.332 1.189 6.374 0.147a0.503 0.503 0 0 1 0.71 0l2.769 2.769a0.502 0.502 0 0 1 0 0.71L3.626 9.853a0.502 0.502 0 0 1 -0.71 0L0.147 7.084Z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
  );
}

import { format_money } from '~/components/Money';
import FormGroup from '~/components/form/FormGroup';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreAerialFPVOddOn from '~/tenants/bre/performable/aerial/BreAerialFPVAddOn';
import BreAerialFields from '~/tenants/bre/performable/aerial/BreAerialFields';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRepeat } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { AerialType } from '~/tenants/bre/performable/aerial/BreAerialConfig';

export default function BreAerialForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { POI, POI_EACH, RUSH, TWILIGHT } = BreAerialRate[context.metadata.type];
  const type = useZodFormFieldSingleValue<AerialType>('type');

  return (
    <FormGroup>
      <BreAerialFields type={context.metadata.type} />

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="twilight"
          title="Aerial Twilight"
          description="Add 5 aerial twilight photos"
          image="" // ? what image should we use here
          persona={persona}
          cost={TWILIGHT}
        />
        {type === 'combo' && (
          <TenantPackageAddOnCheckbox
            name="rush"
            title="Rush"
            description="Need your video by the next business day instead of the second business day?"
            image="" // ? what should we use here
            persona={persona}
            cost={RUSH}
          />
        )}
        <BreAerialFPVOddOn
          persona={persona}
          type={context.metadata.type}
        />
        <TenantPackageAddOnRepeat
          name="poi"
          title="Points of Interest"
          description={`Add-On nearby points of interest. First 7 for only ${format_money(POI)}, then ${format_money(POI_EACH)}/each.`}
          image="" // ? what image should we use here
          persona={persona}
        >
          <div className="flex gap-4">
            <ZodFieldInput name="name" placeholder="Name" />
            <ZodFieldInput name="location" placeholder="Directions" />
          </div>
        </TenantPackageAddOnRepeat>
      </TenantPerformableAddOns>
    </FormGroup>
  )
}

/*
POI only if video


Our FAA approved aerial imaging offers unique and stunning overhead views of your listing.

Our Aerial photos are shot in High Resolution using multiple exposures that are then “layered” over each other to form an extremely natural-looking and detail-rich images.

We can add boundary lines, arrows or other symbols to show proximity to well known locations from your property (for an extra fee)
 */

/*
Property to be photographed or filmed will be "Photo Ready" upon Photographers arrival, otherwise there will be a Same Day cancellation or delay fee.

Same-Day Cancellation = $125 (Less than 24 Hour Notice) or $175 (Less Than 3 Hour Notice).
Delay Fee = $50.00 per every 15 minutes up to 30 minutes
 */

import Sections from '~/components/layout/Sections';
import PerformableGroupForm, { usePerformableGroup } from '~/components/performable/PerformableGroupForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BrePackageGroup from '~/tenants/bre/package/BrePackageGroup';
import {
  BrePackageAerialInclude,
  BrePackageBoostInclude,
  BrePackageCinematicInclude,
  BrePackageFloorplanInclude,
  BrePackageMatterportUpgrade,
  BrePackagePhotosInclude,
  BrePackageWebsiteInclude,
} from '~/tenants/bre/package/common';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreAerialFPVOddOn from '~/tenants/bre/performable/aerial/BreAerialFPVAddOn';
import BreAerialFields from '~/tenants/bre/performable/aerial/BreAerialFields';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';
import BreCinematicAddOns from '~/tenants/bre/performable/cinematic/BreCinematicAddOns';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePhotosFields from '~/tenants/bre/performable/photos/BrePhotosFields';
import BrePhotosLocalAreaAddOn from '~/tenants/bre/performable/photos/BrePhotosLocalAreaAddOn';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreWebsiteDomianAddOn from '~/tenants/bre/performable/website/BreWebsiteDomianAddOn';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageIncludes } from '~/tenants/common/package';

export default function BreCinematicsPackageForm() {
  const { order } = usePerformableGroup<BreOrderContext>();

  return (
    <Sections>
      <TenantPackageIncludes>
        <PerformableGroupForm performable={BrePhotosConfig}>
          <BrePackagePhotosInclude />
        </PerformableGroupForm>
        <BrePackageFloorplanInclude />
        <BrePackageCinematicInclude />
        <PerformableGroupForm performable={BreAerialConfig}>
          <BrePackageAerialInclude />
        </PerformableGroupForm>
        <PerformableGroupForm performable={BreBoostConfig}>
          <BrePackageBoostInclude />
        </PerformableGroupForm>
        <BrePackageWebsiteInclude />
      </TenantPackageIncludes>

      <BrePackageGroup
        subtitle="Customize the ground photography."
        performable={BrePhotosConfig}
      >
        <BrePhotosFields metadata={order.metadata} />
        <BrePhotosLocalAreaAddOn type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="Earth-Tone Color 2D Floorplan."
        performable={BreFloorplanConfig}
      >
        <BrePackageMatterportUpgrade />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="Cinematic films that can include aeria footage"
        performable={BreCinematicConfig}
      >
        <BreCinematicAddOns type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="Aerial photos crafted with layered exposures for natural, detailed results."
        performable={BreAerialConfig}
      >
        <BreAerialFields type={order.metadata.type} />
        <BreAerialFPVOddOn type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="Market your listing in the city of the property."
        performable={BreBoostConfig}
      >
        <BreBoostFields />
      </BrePackageGroup>

      <BrePackageGroup
        subtitle="1-year subscription to a single property website page"
        performable={BreWebsiteConfig}
      >
        <ZodFieldHidden name="type" value="website" />
        <BreWebsiteDomianAddOn type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>
    </Sections>
  );
}

import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import * as v from 'valibot';

export type AerialType = z.infer<typeof typeSchema>;

const typeSchema = z.enum(['five', 'ten', 'video', 'combo']);

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: typeSchema,
      twilight: coerceBoolean().optional(),
      fpv: z.enum(['outside', 'both']).optional(),
      poi: z.array(z.object({ name: z.string(), location: z.string() })).optional(),
      rush: coerceBoolean().optional(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Aerial Photo & Video',
    group: 'Listing',
    images: [],
    short: '',
    tenant: TenantId.BEYOND_RE_MARKETING,
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);

import { createContext, ReactNode, useContext } from 'react';
import Big from 'big.js';
import { State, useHookstate } from '@hookstate/core';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { TenantActionConfig, TenantPerformable } from '~/tenants/common/registry';
import { TenantPerformableConfig } from '~/tenants/common/TenantJob';
import { v4 } from 'uuid';
import ZodForm, { useZodFormData } from '~/components/zod/ZodForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { Deliverable } from '~common/model/Deliverable';

interface PerformableFormState {
  revenue?: Big;
}

interface Context {
  state: State<PerformableFormState>;
  orderContext: TenantOrderContext;
  performable: TenantPerformable<any, any, any>;
}

const PerformableFormContext = createContext<Context | null>(null);

function usePerformableFormContext<Versions extends number>() {
  const context = useContext<Context>(PerformableFormContext as any);

  if (!context) {
    throw new Error('Must be used within a PerformableForm');
  }

  return {
    performable: context.performable,
    orderContext: context.orderContext,
    state: useHookstate(context.state),
  };
}

export function PerformableFormRevenue() {
  const { state } = usePerformableFormContext();
  const revenue = state.revenue.get();

  if (revenue) {
    return <>${revenue.toFixed(2)}</>;
  } else {
    return <>$0.00</>;
  }
}

export function ActionFormCreate<Versions extends number>(props: {
  action: TenantActionConfig;
  deliverables: Deliverable[];
}) {
  const { orderContext } = usePerformableFormContext<Versions>();

  return <props.action.form context={orderContext} deliverables={props.deliverables} />;
}

export function PerformableFormCreate<Versions extends number>(props: {
  persona: PersonaType;
  create?: boolean;
  deliverables?: Deliverable[];
}) {
  const { performable, orderContext } = usePerformableFormContext<Versions>();

  return (
    <performable.form
      persona={props.persona}
      context={orderContext}
      create={props.create ?? false}
      deliverables={props.deliverables ?? []}
    />
  );
}

export default function PerformableForm<P extends TenantPerformableConfig, O extends TenantOrderContext>(props: {
  performable: TenantPerformable<P, O, any>;
  children: ReactNode;
  metadata?: ZodVersionedMetadata<P['schema']>;
  orderContext: O;
}) {
  const version = schema_latest_version(props.performable.config.schema, props.metadata);
  const schema = props.performable.config.schema[version];

  let revenue;

  if (props.metadata) {
    revenue = new props.performable.job(props.orderContext, v4(), props.metadata).revenue();
  }

  const state = useHookstate<PerformableFormState>({ revenue });

  return (
    <PerformableFormContext.Provider
      value={{ performable: props.performable, state, orderContext: props.orderContext }}
    >
      <ZodForm
        schema={schema}
        defaultValues={props.metadata}
        onValid={(data: any) => {
          // TODO: fix data casting?
          state.revenue.set(new props.performable.job(props.orderContext, v4(), data).revenue());
        }}
      >
        <ZodFieldHidden name="version" value={version} />
        {props.children}
      </ZodForm>
    </PerformableFormContext.Provider>
  );
}

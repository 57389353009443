import z from 'zod';
import { DistanceAddressSchema } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata, coerceLiteralNumberRequired } from '~/lib/zod';
import { TenantCustomer } from '~/tenants/common/TenantPersona';

export type BreCustomerMetadata = ZodVersionedMetadata<typeof BreCustomerSchema>;

export const BreCustomerSchema = {
  [FIRST_VERSION_TIMESTAMP]: z.object({
    version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    office: DistanceAddressSchema.optional(),
    home: DistanceAddressSchema.optional(),
  }),
};

export default class BreCustomer extends TenantCustomer<BreCustomerMetadata> {}

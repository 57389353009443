import z from 'zod';
import { object } from 'valibot';
import { DisplayData, ProviderStaticConfig } from '~/lib/model';
import { coerceIsoDate } from '~/lib/zod';
import { CustomerUserSchema } from '~/lib/schema/customer';
import { InvitedUserSchema } from '~/lib/schema/user';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { DateTime } from 'luxon';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TenantWrapper from '~/tenants/common/TenantWrapper';

export enum PersonaType {
  CUSTOMER = 'customer',
  PROVIDER = 'provider',
  OFFICE = 'office',
  EDITOR = 'editor',
}

export enum TenantSubscriptionType {
  MICROSITE = 'microsite',
  MATTERPORT = 'matterport',
}

export enum TenantSubscriptionStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export const TenantSubscriptionSchema = z
  .object({
    status: z.nativeEnum(TenantSubscriptionStatus),
    expires: coerceIsoDate(),
    renew: z.boolean(),
    name: z.string(),
    months: z.number(),
    cost: z.number(),
    order_id: z.string().optional(),
    job_id: z.string().optional(),
    created: coerceIsoDate(),
    payments: z.record(
      z.string(),
      z.object({
        amount: z.number(),
        created: z.number(),
      }),
    ),
  })
  .and(
    z.discriminatedUnion('type', [
      z.object({ type: z.literal(TenantSubscriptionType.MICROSITE), domain: z.string() }),
      z.object({ type: z.literal(TenantSubscriptionType.MATTERPORT), url: z.string() }),
    ]),
  );

export type TenantSubscriptionMetadata = z.output<typeof TenantSubscriptionSchema>;

export type CustomerUserData = z.output<typeof CustomerUserSchema>;

export const EditorUserSchema = InvitedUserSchema;

export type EditorUserData = z.output<typeof EditorUserSchema>;

export const EditorSchema = z.object({
  name: z.string({ required_error: 'Editor name is required' }),
  users: z.record(z.string(), EditorUserSchema).default({}),
});

export class TenantProvider<M = {}, Context extends TenantOrderContext = TenantOrderContext> {
  constructor(
    public readonly tenant: TenantWrapper,
    public readonly id: string,
    public readonly config: ProviderStaticConfig & M,
  ) {}

  isWithinTravelTime(_context: Context, _job: Context['jobs'][0], _time: DateTime): string | true {
    return true;
  }

  get payoutTempId() {
    return `temp-${this.id}`;
  }

  get hidden() {
    return this.config.hidden;
  }

  get location() {
    return this.tenant.location(this.config.location);
  }

  get first() {
    return this.config.first;
  }

  get headshot() {
    return this.config.headshot;
  }

  get name() {
    return `${this.config.first} ${this.config.last}`;
  }

  get performableIds() {
    return [] as string[];
  }

  canPerform(context: Context, _job: Context['jobs'][0]): true | string {
    // TODO: should be a warning
    //return context.location === this.config.location ? true : 'This provider does not service the order address.';

    return true;
  }
}

export class TenantCustomer<M = {}> {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly metadata: M,
  ) {}

  get info(): DisplayData[] {
    return [];
  }

  get onboarded(): boolean {
    return true;
  }
}

import { NeedBy } from '~/tenants/bre/performable/common';
import { BookletCount, BookletPaper, BookletSide } from '~/tenants/bre/performable/print/booklets/BreBookletsConfig';

type CountMap = Record<BookletCount, string>;

type PaperMap = Record<BookletPaper, CountMap>;

type SideMap = Record<BookletSide, Partial<PaperMap>>;

type NeedByMap = Record<NeedBy, Partial<SideMap>>;

export const BreBookletsRate: NeedByMap = {
  next_day: {
    8: {
      heavy_low_gloss_uv: {
        50: '490',
        100: '635',
        150: '815',
        200: '970',
      },
      heavy_heavy_gloss_uv: {
        50: '510',
        100: '645',
        150: '825',
        200: '980',
      },
      super_heavy_low_gloss_uv: {
        50: '525',
        100: '665',
        150: '840',
        200: '995',
      },
    },
    12: {
      heavy_low_gloss: {
        50: '670',
        100: '805',
        150: '1060',
        200: '1285',
      },
    },
    16: {
      heavy_low_gloss_uv: {
        50: '710',
        100: '985',
        150: '1305',
        200: '1640',
      },
    },
    20: {
      heavy_low_gloss_uv: {
        50: '885',
        100: '123',
        150: '1630',
        200: '1995',
      },
    },
    24: {
      heavy_low_gloss_uv: {
        50: '1020',
        100: '1435',
        150: '1900',
        200: '2335',
      },
    },
    28: {
      heavy_low_gloss_uv: {
        50: '1150',
        100: '1635',
        150: '2170',
        200: '2560',
      },
    },
  },
  same_day: { },
};

export const BreBookletsPay: NeedByMap = {
  next_day: {
    8: {
      heavy_low_gloss_uv: {
        50: '425',
        100: '550',
        150: '705',
        200: '840',
      },
      heavy_heavy_gloss_uv: {
        50: '440',
        100: '560',
        150: '715',
        200: '850',
      },
      super_heavy_low_gloss_uv: {
        50: '455',
        100: '575',
        150: '730',
        200: '865',
      },
    },
    12: {
      heavy_low_gloss: {
        50: '570',
        100: '700',
        150: '920',
        200: '1115',
      },
    },
    16: {
      heavy_low_gloss_uv: {
        50: '615',
        100: '855',
        150: '1135',
        200: '1395',
      },
    },
    20: {
      heavy_low_gloss_uv: {
        50: '770',
        100: '1070',
        150: '1415',
        200: '1735',
      },
    },
    24: {
      heavy_low_gloss_uv: {
        50: '885',
        100: '1245',
        150: '1650',
        200: '2030',
      },
    },
    28: {
      heavy_low_gloss_uv: {
        50: '1000',
        100: '1420',
        150: '1885',
        200: '2225',
      },
    },
  },
  same_day: { },
};

import { TenantOrder } from '../../common/TenantOrder';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import Twt3dConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import { TwtCustomerMetadata } from '~/tenants/twt/model/TwtCustomer';
import { AppointmentCancel } from '~/lib/enum';
import { TWT_TRAVEL_FEES } from '~/tenants/twt/data';
import { TwtProviderStaticData } from '~/tenants/twt/model/TwtProvider';
import { Holiday, holiday_lookup } from '~common/holidays/holidays';
import { datetime, formalTime } from '~/lib/datettime';
import { DateTime } from 'luxon';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';
import TwtEditingConfig from '~/tenants/twt/performable/editing/TwtEditingConfig';
import TwtStagingConfig from '~/tenants/twt/performable/staging/TwtStagingConfig';
import TwtAerialConfig from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import TwtVirtualConfig from '~/tenants/twt/performable/virtual/TwtVirtualConfig';
import TwtZillowConfig from '~/tenants/twt/performable/zillow/TwtZillowConfig';
import TwtTwilightConfig from '~/tenants/twt/performable/twilight/TwtTwilightConfig';
import { OrderCustomTag } from '~/lib/schema/order';
import TwtSlideshowConfig from '~/tenants/twt/performable/slideshow/TwtSlideshowConfig';
import TwtMatterportVideoConfig from '~/tenants/twt/performable/matterport-video/TwtMatterportVideoConfig';

export enum TwtOrderType {
  FOR_SALE_LISTING = 'for_sale_listing',
  LONG_TERM_RENTAL = 'long_term_rental',
  SHORT_TERM_RENTAL = 'short_term_rental',
  COMMERCIAL = 'commercial',
}

export enum TwtOccupancy {
  VACANT = 'vacant',
  TENANT = 'tenant',
  SELLER = 'seller',
}

export enum TwtPropertyType {
  BUILDING = 'building',
  SINGLE_FAMILY = 'single_family',
  MULTI_FAMILY = 'family_family',
  TOWNHOME = 'townhome',
  CONDO = 'condo',
  LAND = 'land',
  APARTMENT = 'apartment',
}

export enum TwtAccessType {
  MEET = 'meet',
  KEY = 'key',
  LOCKBOX = 'lockbox',
  LATER = 'later',
  EXTERIOR = 'exterior',
}

export const TWT_ACCESS_TYPE_NAME: Record<TwtAccessType, string> = {
  [TwtAccessType.KEY]: 'Will be unlocked or key will be under mat',
  [TwtAccessType.LOCKBOX]: 'Lockbox or supra',
  [TwtAccessType.MEET]: 'Someone will meet the provider',
  [TwtAccessType.LATER]: 'Will provide Access Info Later',
  [TwtAccessType.EXTERIOR]: 'Exteriors only | No access needed',
};

export enum TwtAreas {
  GARAGE = 'garage',
  LAUNDRY = 'laundry',
  CLOSET = 'closet',
}

const schema = z
  .object({
    version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    access: z.nativeEnum(TwtAccessType, { required_error: 'Please select the type of access to the property.' }),
    code: z.string().optional(),
    gate: z.string().optional(),
    unit: z.string().optional(),
    occupancy: z.nativeEnum(TwtOccupancy).optional(),
    speed: z.union([z.literal('rush'), z.literal('priority'), z.literal('standard')]),
    subtype: z.nativeEnum(TwtPropertyType),
    acres: z.coerce.number().lt(5, 'Acres must be less than 5.').optional(),
    fallback: coerceBoolean().optional(),
    special: z.string().optional(),
    sqft: z.coerce
      .number({
        required_error: 'Please provide the square footage of the property or unit.',
        invalid_type_error: 'Please provide the square footage of the property or unit.',
      })
      .min(1, { message: 'The square footage of the property or unit must be greater than 1.' })
      .optional(),
  })
  .and(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal(TwtOrderType.FOR_SALE_LISTING),
        areas: z.array(z.nativeEnum(TwtAreas)).optional(),
      }),
      z.object({
        type: z.literal(TwtOrderType.LONG_TERM_RENTAL),
      }),
      z.object({
        type: z.literal(TwtOrderType.SHORT_TERM_RENTAL),
      }),
      z.object({
        type: z.literal(TwtOrderType.COMMERCIAL),
      }),
    ]),
  );

export const TWT_PROPERTY_TYPE_UNITS = [
  TwtPropertyType.MULTI_FAMILY,
  TwtPropertyType.CONDO,
  TwtPropertyType.APARTMENT,
  TwtPropertyType.TOWNHOME,
];

export const TwtOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: schema
    .refine((data) => !TWT_PROPERTY_TYPE_UNITS.includes(data.subtype) || (data.unit && data.unit.length > 0), {
      message: 'Please provide a unit number.',
    })
    .refine((data) => data.subtype != TwtPropertyType.LAND || (data.acres && data.acres > 0), {
      message: 'Please provide the total number of acres.',
    })
    .refine((data) => data.access !== TwtAccessType.LOCKBOX || (data.code && data.code.length > 0), {
      message: 'Please provide the access code for the lock box.',
    })
    .refine((data) => data.subtype === TwtPropertyType.LAND || (data.sqft && data.sqft > 0), {
      message: 'Please provide the square footage of property.',
    }) as any as typeof schema,
};

export type TwtOrderMetadata = ZodVersionedMetadata<typeof TwtOrderSchema>;

export type TwtPerformableConfig =
  | typeof Twt3dConfig
  | typeof TwtFloorplanConfig
  | typeof TwtPhotoConfig
  | typeof TwtVideoConfig
  | typeof TwtAerialConfig
  | typeof TwtReelConfig
  | typeof TwtVirtualConfig
  | typeof TwtStagingConfig
  | typeof TwtZillowConfig
  | typeof TwtEditingConfig
  | typeof TwtTwilightConfig
  | typeof TwtSlideshowConfig
  | typeof TwtMatterportVideoConfig;

export type TwtOrderContext = TenantOrderContext<
  TwtOrderMetadata,
  TwtPerformableConfig,
  TwtCustomerMetadata,
  TwtProviderStaticData
>;

const holidays = [
  Holiday.MEMORIAL_DAY,
  Holiday.LABOR_DAY,
  Holiday.JULY_4TH,
  Holiday.CHRISTMAS_EVE,
  Holiday.NEW_YEARS_EVE,
];

export class TwtOrder extends TenantOrder<TwtOrderContext> {
  get taxable() {
    const paid = this.context.paid ? new Date(this.context.paid) : null;

    return !paid || paid > new Date(1729536042000);
  }

  info(): Array<DisplayData> {
    const info = super.info();

    let type = '';

    switch (this.context.metadata.type) {
      case TwtOrderType.FOR_SALE_LISTING:
        switch (this.context.metadata.subtype) {
          case TwtPropertyType.SINGLE_FAMILY:
            type = 'Residential: Single-Family';
            break;
          case TwtPropertyType.TOWNHOME:
            type = 'Residential: Townhome';
            break;
          case TwtPropertyType.CONDO:
            type = 'Residential: Condo';
            break;
          case TwtPropertyType.APARTMENT:
            type = 'Residential: Apartment';
            break;
        }
        break;
      case TwtOrderType.LONG_TERM_RENTAL:
        type = 'Long Term Rental';
        break;
      case TwtOrderType.SHORT_TERM_RENTAL:
        type = 'Short Term Rental';
        break;
      case TwtOrderType.COMMERCIAL:
        type = 'Commercial Property';
        break;
    }

    if (this.context.metadata.speed === 'rush') {
      info.push({
        name: 'Delivery Speed',
        invoice: true,
        value: 'Rush (7PM Same Day)',
        provider: true,
        customer: true,
        schedule: true,
      });
    } else if (this.context.metadata.speed === 'priority') {
      info.push({
        name: 'Delivery Speed',
        invoice: true,
        value: 'Priority (9AM Next Day)',
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    if (type) {
      info.push({
        name: 'Order Type',
        invoice: true,
        value: type,
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    info.push({
      name: 'Alternative Team Member',
      value: this.context.metadata.fallback ? 'Yes' : 'No',
      schedule: true,
    });

    if (this.context.metadata.access) {
      info.push({
        name: 'Property Access',
        value: TWT_ACCESS_TYPE_NAME[this.context.metadata.access],
        schedule: true,
        customer: true,
        provider: true,
      });
    }

    if (this.context.metadata.special) {
      info.push({
        name: 'Special Instructions',
        invoice: false,
        value: this.context.metadata.special,
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    if (this.context.metadata.sqft) {
      info.push({
        name: 'Square Feet',
        invoice: true,
        provider: true,
        customer: true,
        schedule: true,
        value: this.context.metadata.sqft.toString(),
      });
    }

    if (this.context.metadata.unit) {
      info.push({
        name: 'Unit #',
        provider: true,
        schedule: true,
        customer: true,
        invoice: true,
        value: this.context.metadata.unit,
      });
    }

    if (this.context.metadata.gate) {
      info.push({
        name: 'Gate Code',
        provider: true,
        schedule: true,
        customer: true,
        value: this.context.metadata.gate,
      });
    }

    if (this.context.metadata.code) {
      info.push({
        name: 'Lockbox Code',
        provider: true,
        schedule: true,
        customer: true,
        value: this.context.metadata.code,
      });
    }

    return info;
  }

  get tags(): OrderCustomTag[] {
    const tags: OrderCustomTag[] = [];

    if (this.context.buyer.matterport_transfer) {
      tags.push({
        name: 'Transfer 3D Scan',
        color: 'teal',
      });
    }

    if (this.context.metadata.access === TwtAccessType.LATER) {
      tags.push({
        name: 'Missing Access',
        color: 'red',
      });
    }

    if (this.context.metadata.speed === 'rush') {
      tags.push({
        name: 'Rush',
        color: 'red',
      });
    }

    if (this.context.metadata.speed === 'priority') {
      tags.push({
        name: 'Priority',
        color: 'yellow',
      });
    }

    if (this.context.jobs.some((job) => job.performable_id === 'editing')) {
      tags.push({
        name: 'Edit Request',
        color: 'red',
      });
    }

    if (this.context.jobs.some((job) => job.performable_id === 'floorplan')) {
      tags.push({
        name: 'Floorplan',
        color: 'blue',
      });
    }

    if (this.context.jobs.some((job) => job.performable_id === 'staging')) {
      tags.push({
        name: 'Staging',
        color: 'blue',
      });
    }

    if (this.context.jobs.some((job) => job.performable_id === 'virtual')) {
      tags.push({
        name: 'Virtual',
        color: 'blue',
      });
    }

    return tags;
  }

  // get emails() {
  //   const emails = super.emails;
  //
  //   if (this.context.metadata.type === TwtOrderType.FOR_SALE_LISTING) {
  //     emails.push({
  //       id: 'first_order',
  //       once: true,
  //       trigger: TenantOrderEmailTrigger.ORDER_COMPLETE,
  //       template: TwtEmailFirstOrder,
  //     });
  //   }
  //
  //   emails.push({
  //     id: 'lock_day',
  //     trigger: TenantOrderEmailTrigger.APPOINTMENT_LOCKED,
  //     template: TwtEmailFirstOrder,
  //   });
  //
  //   return emails;
  // }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    for (const appointment of this.context.appointments) {
      const address = appointment.address ?? this.context.address;

      if (appointment.canceled) {
        const jobs = this.context.jobs.filter((job) => appointment.job_ids.includes(job.id));

        const twilight = jobs.some((job) => job.performable_id === 'photo' && job.metadata.twilight);

        const type =
          appointment.canceled === AppointmentCancel.ONSITE
            ? 'Cancellation Compensation For Photographer'
            : 'Reschedule Rate';

        lines.push({
          id: `cancel-${appointment.id}`,
          description: `${type} (${datetime(appointment.start, this.location.timezone).toFormat('MM/dd @ h:mm a')})`,
          appointment_id: appointment.id,
          amount:
            appointment.canceled === AppointmentCancel.ENROUTE ? new Big(45) : twilight ? new Big(90) : new Big(65),
        });
      } else if (address) {
        const fee = TWT_TRAVEL_FEES[address.zip];

        if (fee) {
          lines.push({
            id: `travel-${appointment.id}`,
            appointment_id: appointment.id,
            description: `Travel Compensation - ${datetime(appointment.start, this.location.timezone).toFormat(
              'MM/dd',
            )}`,
            amount: new Big(fee.expense),
          });
        }
      }
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    const firstOrderId = this.context.buyer.discount_first_order_id ?? this.context.id;

    if (this.context.buyer.discount_first_dollars && this.context.id === firstOrderId) {
      lines.push({
        id: 'first_order',
        description: 'First Order Discount',
        taxable: true,
        discount: true,
        amount: new Big(this.context.buyer.discount_first_dollars).times(-1),
      });
    }

    const secondOrderId = this.context.buyer.discount_second_order_id ?? this.context.id;

    if (this.context.buyer.discount_second_dollars && this.context.id === secondOrderId) {
      lines.push({
        id: 'second_order',
        description: 'Second Order Discount',
        taxable: true,
        discount: true,
        amount: new Big(this.context.buyer.discount_second_dollars).times(-1),
      });
    }

    if (this.context.metadata.speed === 'rush') {
      lines.push({
        id: 'rush',
        description: 'Rush Delivery',
        taxable: true,
        amount: new Big(60),
      });
    }

    if (this.context.metadata.speed === 'priority') {
      lines.push({
        id: 'priority',
        description: 'Priority Delivery',
        taxable: true,
        amount: new Big(30),
      });
    }

    if (!this.hasActiveAppointments && this.context.jobs.length > 0) {
      if (this.context.address) {
        const fee = TWT_TRAVEL_FEES[this.context.address.zip];

        if (fee) {
          lines.push({
            id: 'travel',
            description: 'Travel Compensation',
            taxable: true,
            amount: new Big(fee.revenue),
          });
        }
      }

      const twilight = this.context.jobs.some((job) => job.performable_id === 'photo' && job.metadata.twilight);

      for (const { day } of this.context.requested) {
        const date = datetime(day, this.location.timezone);
        const fee = date_fee('requested', date, twilight);

        if (fee) {
          lines.push({
            ...fee,
            taxable: true,
          });

          break;
        }
      }
    }

    for (const appointment of this.context.appointments) {
      const address = appointment.address ?? this.context.address;
      const jobs = this.context.jobs.filter((job) => appointment.job_ids.includes(job.id));
      const twilight = jobs.some((job) => job.performable_id === 'photo' && (job.metadata.twilight || job.metadata.sunrise));

      if (appointment.canceled) {
        lines.push({
          id: `cancel-${appointment.id}`,
          description: `Cancellation Fee (${formalTime(datetime(appointment.start, this.location.timezone))})`,
          appointment_id: appointment.id,
          taxable: true,
          amount: appointment.canceled === AppointmentCancel.ONSITE ? new Big(70) : new Big(50),
        });
      } else {
        const date = datetime(appointment.start, this.location.timezone);

        const fee = date_fee(appointment.id, date, twilight);

        if (fee) {
          lines.push({
            ...fee,
            taxable: true,
          });
        }

        if (address) {
          const fee = TWT_TRAVEL_FEES[address.zip];

          if (fee) {
            lines.push({
              id: `travel-${appointment.id}`,
              description: `Travel Compensation (${datetime(appointment.start, this.location.timezone).toFormat(
                'MM/dd',
              )})`,
              appointment_id: appointment.id,
              taxable: true,
              amount: new Big(fee.revenue),
              fee: 'travel',
            });
          }
        }
      }
    }

    const pct = this.context.buyer.discount_percentage ?? this.context.parent?.discount_percentage;

    if (pct) {
      const discountable = lines
        .filter((line) => line.discountable)
        .reduce((sum, line) => sum.plus(line.amount), Big(0));

      const discount = discountable.times(pct / 100);

      if (discount.gt(0)) {
        lines.push({
          amount: new Big(discount).times(-1),
          description: 'Order Discount',
          id: 'order_discount',
          taxable: true,
          discount: true,
        });
      }
    }

    return lines;
  }
}

function date_fee(id: string, date: DateTime, twilight = false): TenantOrderLine | null {
  const holidays = holiday_lookup(date);

  if (
    [
      Holiday.LABOR_DAY,
      Holiday.MEMORIAL_DAY,
      Holiday.JULY_4TH,
      Holiday.CHRISTMAS_EVE,
      Holiday.NEW_YEARS_EVE,
    ].some((h) => holidays.includes(h))
  ) {
    return {
      id: `holiday-${id}`,
      description: 'Holiday Fee',
      amount: new Big(50),
      fee: 'holiday',
    };
  }

  if ((date.hour < 9 || date.hour >= 16) && !twilight) {
    return {
      id: `primetime-${id}`,
      description: 'Primetime Fee',
      amount: new Big(50),
      fee: 'tod',
    };
  }

  if (date.weekday >= 6) {
    return {
      id: `weekend-${id}`,
      description: 'Weekend Fee',
      amount: new Big(50),
      fee: 'weekend',
    };
  }

  return null;
}

import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreSlideshowConfig from '~/tenants/bre/performable/slideshow/BreSlideshowConfig';
import { DeliverableType } from '~common/model/Deliverable';

const BaseRate = {
  SLIDESHOW: '50',
};

export const BreSlideshowRate: Record<BreOrderType, typeof BaseRate> = {
  [BreOrderType.COMMERCIAL]: BaseRate,
  [BreOrderType.MARKETING]: BaseRate,
  [BreOrderType.RENTAL]: BaseRate,
  [BreOrderType.RESIDENTIAL]: BaseRate,
};

export default class BreSlideshowJob extends BreJob<typeof BreSlideshowConfig> {
  get performable() {
    return BreSlideshowConfig;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.SLIDESHOW;
  }

  get eligible(): boolean {
    return this.order.metadata.type !== BreOrderType.MARKETING;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreSlideshowConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { SLIDESHOW } = BreSlideshowRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'slideshow_video',
      description: 'Social Media Slideshow',
      amount: new Big(SLIDESHOW),
    });

    return lines;
  }
}

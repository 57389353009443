import { TenantJob } from '~/tenants/common/TenantJob';
import { BreOrderContext, BrePerformableConfig } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';

export default abstract class BreJob<P extends BrePerformableConfig = BrePerformableConfig> extends TenantJob<
  BreOrderContext,
  P
> {
  protected get matchSocialPackage(): boolean {
    const hasPhotos = this.order.jobs.some((j) => j.performable_id === BrePhotosConfig.id && j.metadata.photos >= 15);
    const hasWebsite = this.order.jobs.some((j) => j.performable_id === BreWebsiteConfig.id && j.metadata.type === 'website');
    const hasFloorplans = this.order.jobs.some((j) => j.performable_id === BreFloorplanConfig.id);
    const hasBoost = this.order.jobs.some((j) => j.performable_id === BreBoostConfig.id);
    const hasSocial = this.order.jobs.some((j) => j.performable_id === BreSocialConfig.id);
    const hasDusk = this.order.jobs.some((j) => j.performable_id === BreDuskConfig.id);

    return hasPhotos && hasWebsite && hasFloorplans && hasBoost && hasSocial && hasDusk;
  }

  isDelivery(): boolean {
    return false;
  }

  isPrint(): boolean {
    return false;
  }
}

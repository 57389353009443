import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import { TwtOrderType } from '~/tenants/twt/model/TwtOrder';

export default createPerformableConfig(
  'floorplan',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      upgrade: z.union([z.literal('plus').optional(), z.literal('3d').optional()]),
      detached: z.string().array().optional(),
    }),
  },
  {
    name: '2D Floor Plan',
    thumbnail(metadata: Record<string, unknown>) {
      if ('type' in metadata && metadata.type === TwtOrderType.COMMERCIAL) {
        return 'tenant/twt/floorplan/commercial.svg';
      }

      return 'tenant/twt/floorplan/standard2.png';
    },
    color: '#811F61',
    short:
      "Capture a clear, bird's-eye view of your property, allowing buyers to grasp the relationships between rooms, their sizes, and how they connect.",
    tenant: TenantId.TWIST_TOURS,
    images: [],
  },
);

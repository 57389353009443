import { BusinessCardCount, BusinessCardPaper } from '~/tenants/bre/performable/print/cards/BreBusinessCardsConfig';

type CountMap = Record<BusinessCardCount, string>;

type PaperMap = Record<BusinessCardPaper, Partial<CountMap>>;

export const BreBusinessCardsRate: PaperMap = {
  uv_glossy: {
    50: '70',
    100: '75',
    250: '85',
    500: '95',
    1000: '120',
    2500: '185',
    5000: '276',
  },
  satin_matte: {
    50: '85',
    100: '90',
    250: '110',
    500: '125',
    1000: '160',
    2500: '240',
    5000: '360',
  },
};

export const BreBusinessCardsPay: PaperMap = {
  uv_glossy: {
    50: '55',
    100: '60',
    250: '70',
    500: '80',
    1000: '100',
    2500: '155',
    5000: '230',
  },
  satin_matte: {
    50: '70',
    100: '75',
    250: '90',
    500: '105',
    1000: '135',
    2500: '200',
    5000: '300',
  },
};

import React from 'react';
import Divider from '~/components/layout/Divider';
import Heading from '~/components/layout/Heading';
import FormGroup from '~/components/form/FormGroup';
import { PersonaType } from '~/tenants/common/TenantPersona';

interface TenantPerformableAddOnsProps {
  children?: React.ReactNode;
  persona: PersonaType;
  title?: string;
  subtitle?: string;
}

export function TenantPerformableConfiguration({ children }: { children?: React.ReactNode }) {
  return (
    <div className="space-y-related w-full">
      <Heading title="Configuration" />

      <FormGroup>{children}</FormGroup>
    </div>
  );
}

export function TenantPerformableAddOns(props: TenantPerformableAddOnsProps) {
  const {
    children,
    persona,
    title = 'Add-Ons',
    subtitle = 'Enhance your order with these optional add-ons!',
  } = props;

  if (persona === PersonaType.CUSTOMER) {
    return (
      <>
        <Divider />
        <div className="space-y-5 w-full">
          <Heading title={title}>{subtitle}</Heading>

          <div className="space-y-10">{children}</div>
        </div>
      </>
    );
  }

  return <div className="space-y-6 border-t border-theme-separator pt-6">{children}</div>;
}

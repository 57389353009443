import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, coerceOptionalPositiveNumber, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import dedent from 'dedent';

export default createPerformableConfig(
  'staging',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      style: z.enum([
        'modern',
        'scandinavian',
        'contemporary',
        'hamptons',
        'traditional',
        'farmhouse',
        'urban',
        'outdoor',
      ]),
      quantity: z.coerce.number().positive(),
      item_removal: coerceOptionalPositiveNumber(),
      space_renovation: coerceOptionalPositiveNumber(),
    }),
  },
  {
    name: 'Virtual Staging',
    group: 'Listing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: dedent`
      We can virtually furnish an entire room or outdoor patio for just $50.00 \
      for up to 3 images. The more you order the less each image is (4-6 images \
      is $45 each, 7-9 images is $40 each and 10 or more is $35 each).
    `,
  },
);
